export const googleAuth = async (credentialResponse, { onSuccess, onError }) => {
    try {
        const { credential } = credentialResponse;
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/user/google_login/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id_token: credential }),
        });

        if (response.ok) {
            const token = await response.json();
            localStorage.setItem('access_token', token.access);
            localStorage.setItem('refresh_token', token.refresh);

            onSuccess && onSuccess(token);
        } else {
            const errorData = await response.json();
            onError && onError(errorData.detail);
        }
    } catch (error) {
        console.error(error);
        onError && onError('An error occurred. Please try again.');
    }
};
