export const createCell = (x, y, color = "#000000") => {
    const cell = {
                x,
                y,
                key: `${y}-${x}`,
                color: color,
                highlighted: false,
                selected: false,
            }
    return cell;
}