import nearestColor from "nearest-color";
import colorNameList from 'color-name-list';

export const getUniqueColorsWithCount = (matrix) => {
    // Create an object to store unique colors and their counts
    const colorCount = {};

    // Iterate through each row in the matrix
    for (let i = 0; i < matrix.length; i++) {
        // Iterate through each object in the row
        for (let j = 0; j < matrix[i].length; j++) {
            // Access the "color" property of the object
            const color = matrix[i][j].color;

            // Update the count for the color in the object
            if (colorCount[color]) {
                colorCount[color]++;
            } else {
                colorCount[color] = 1;
            }
        }
    }

    // Convert the object to an array of objects
    const uniqueColorsWithCount = Object.entries(colorCount).map(([color, count], id) => ({
        id,
        color,
        count,
        originalColorName: nearest(color).name,
        newColorName: nearest(color).name,
    })).sort((a, b) => b.count - a.count);


    return uniqueColorsWithCount;
}

export const nearest = nearestColor.from(colorNameList.reduce((o, {name, hex}) => Object.assign(o, {[name]: hex}), {}))

export const isColorLight = (hexColor) => {
    // Convert hex to RGB
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);

    // Calculate relative luminance
    const luminance = 0.2126 * r / 255 + 0.7152 * g / 255 + 0.0722 * b / 255;
    // Use 0.5 as the threshold, adjust as needed
    return luminance > 0.5;
}