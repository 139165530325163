import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const buildSvgs = (pathKey) => {
    const paths = {
        alphaPatternUpload: require.context('../../Images/Home/AlphaPattern/Upload', false, /\.svg$/),
        alphaPatternEdit: require.context('../../Images/Home/AlphaPattern/Edit', false, /\.svg$/),
        alphaPatternCount: require.context('../../Images/Home/AlphaPattern/Count', false, /\.svg$/),
        alphaPatternExport: require.context('../../Images/Home/AlphaPattern/Export', false, /\.svg$/),
        symbolPatternDesign: require.context('../../Images/Home/SymbolPattern/Design', false, /\.svg$/),
        symbolPatternLayer: require.context('../../Images/Home/SymbolPattern/Layer', false, /\.svg$/),
        symbolPatternExport: require.context('../../Images/Home/SymbolPattern/Export', false, /\.svg$/),
        rowCounterCount: require.context('../../Images/Home/RowCounter/Count', false, /\.svg$/),
    };
    const svgs = paths[pathKey];
    if (!svgs) return [];
    return svgs.keys().map(svgPath => svgs(svgPath));
}

const FeatureFrames = ({ pathKey, alt }) => {
    const [currentFrame, setCurrentFrame] = useState(0);
    const svgs = buildSvgs(pathKey);
    const frameCount = svgs.length;

    // Cycle through the frames
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentFrame((prevFrame) => (prevFrame + 1) % frameCount);
        }, 1000); // Change every 100ms (adjust as needed for your animation speed)

        return () => clearInterval(interval); // Clean up on component unmount
    }, [frameCount]);

    return (
        <motion.img
            key={currentFrame}
            src={svgs[currentFrame]}
            alt={alt}
            initial={{ opacity: 0.5 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            style={{
                height: '20vh',
                width: 'auto',
            }}        />
    );
};

export default FeatureFrames;