import React, {useEffect, useRef, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {useMantineTheme} from "@mantine/core";

const SymbolPatternCanvas = ({
                                 viewport,
                                 setViewport,
    symbols,
                                 selectedSymbol,
                                 canvasSymbols,
                                 setCanvasSymbols,
                                 symbolSize,
                                 selectedLayer,
                                 layers,
                                 accordion,
                                 clickedSymbol,
                                 setClickedSymbol,
    canvasRef,
    bufferCanvasRef,
                             }) => {
    const containerRef = useRef(null);
    const requestIdRef = useRef(null);
    const theme = useMantineTheme();

    const boxSize = 20;
    const widthMultiplier = 1000;


    const getMouseCoordinates = (e) => {
        return {
            x: Math.floor((e.clientX - canvasRef.current.offsetLeft + viewport.x) / (containerWidth * viewport.scale)),
            y: Math.floor((e.clientY - canvasRef.current.offsetTop + viewport.y) / (containerWidth * viewport.scale)),
        };
    };

    let mouseX = 0;
    let mouseY = 0;
    const [containerWidth, setContainerWidth] = useState(1);
    const [mouseDown, setMouseDown] = useState(false);
    const [currentRotation, setCurrentRotation] = useState(0);
    const [currentSize, setCurrentSize] = useState(100);
    const [currentLocation, setCurrentLocation] = useState(null);
    // ToDo: Pretty sure I can simplify rotating/resizing/moving to just be mouseMode since the first three are redundant
    const [rotating, setRotating] = useState(false);
    const [resizing, setResizing] = useState(false);
    const [moving, setMoving] = useState(false);
    const [mouseMode, setMouseMode] = useState(null);


    const handleMouseDown = (e) => {
        const {x, y} = getMouseCoordinates(e);
        mouseX = x * viewport.scale * containerWidth;
        mouseY = y * viewport.scale * containerWidth;
        if (clickedSymbol) {
            const scaledClickedSymbol = calculateSymbolScale(clickedSymbol)
            const rectX = scaledClickedSymbol.scaledX
            const rectY = scaledClickedSymbol.scaledY
            const rotationHandleWidth = 10
            const rotationHandleHeight = 20
            const rotationHandleOffsetX = (scaledClickedSymbol.width / 2) + 20
            const rotationHandleOffsetY = - 10
            const rotation = scaledClickedSymbol.rotation
            const inRotateHandle = isPointInRotatedBox(mouseX, mouseY, rectX, rectY, rotationHandleWidth, rotationHandleHeight, rotationHandleOffsetX, rotationHandleOffsetY, rotation);
            const resizeHandleWidth = 10
            const resizeHandleHeight = 20
            const resizeHandleOffsetX = -(scaledClickedSymbol.width / 2) - 30
            const resizeHandleOffsetY = - 10
            const inResizeHandle = isPointInRotatedBox(mouseX, mouseY, rectX, rectY, resizeHandleWidth, resizeHandleHeight, resizeHandleOffsetX, resizeHandleOffsetY, rotation);
            const selectBoxWidth = scaledClickedSymbol.width + boxSize
            const selectBoxHeight = scaledClickedSymbol.height + boxSize
            const selectBoxOffsetX = -(scaledClickedSymbol.width / 2) - (boxSize / 2)
            const selectBoxOffsetY = -(scaledClickedSymbol.height / 2) - (boxSize / 2)
            const inSelectBox = isPointInRotatedBox(mouseX, mouseY, rectX, rectY, selectBoxWidth, selectBoxHeight, selectBoxOffsetX, selectBoxOffsetY, rotation)
            if (inRotateHandle) {
                setMouseDown(true)
                setMouseMode("rotating")
                return
            }
            else if (inResizeHandle) {
                setMouseDown(true)
                setMouseMode("resizing")
                return
            }
            else if (inSelectBox) {
                setMouseDown(true)
                setMouseMode("moving")
                return
            }
        }
        setMouseMode(null)
    };

    const handleMouseMove = (e) => {
        const { x, y } = getMouseCoordinates(e);
        mouseX = x * viewport.scale * containerWidth;
        mouseY = y * viewport.scale * containerWidth;
        if (mouseDown && clickedSymbol) {
            if (mouseMode === "rotating") {
                // Calculate the new rotation angle based on mouse movement
                const dx = mouseX - clickedSymbol.scaledX;
                const dy = mouseY - clickedSymbol.scaledY;
                const rotation = Math.atan2(dy, dx);
                const updatedClickedSymbol = {...clickedSymbol, rotation }
                setClickedSymbol(updatedClickedSymbol)
                setCurrentRotation(rotation)
                if (!rotating) {
                    setRotating(true)
                }
            }
            if (mouseMode === "resizing") {
                const dx = mouseX - clickedSymbol.scaledX;
                const dy = mouseY - clickedSymbol.scaledY;
                const cosAngle = Math.cos(clickedSymbol.rotation);
                const sinAngle = Math.sin(clickedSymbol.rotation);
                const unrotatedX = dx * cosAngle + dy * sinAngle + clickedSymbol.scaledX;
                const resizeHandleX = clickedSymbol.scaledX - (clickedSymbol.width / 2) - 25
                const resizeDiff = unrotatedX - resizeHandleX
                const updatedSize = clickedSymbol.size - resizeDiff
                if (updatedSize > 0) {
                    const updatedClickedSymbol = calculateSymbolScale({...clickedSymbol, size: updatedSize})
                    setClickedSymbol(updatedClickedSymbol)
                    setCurrentSize(updatedSize)
                }
                if (!resizing) {
                    setResizing(true)
                }
            }
            else if (mouseMode === "moving") {
                clickedSymbol.scaledX = mouseX;
                clickedSymbol.scaledY = mouseY;
                const location = {
                    x: mouseX,
                    y: mouseY,
                }
                const updatedClickedSymbol = {...clickedSymbol, scaledX: location.x, scaledY: location.y}
                setClickedSymbol(updatedClickedSymbol)
                setCurrentLocation(location)
                if (!moving) {
                    setMoving(true)
                }
            }
        }
    };
    const isPointInRotatedBox = (pointX, pointY, rectX, rectY, width, height, offsetX, offsetY, rotation) => {
        // Inverse rotation to bring the point back to unrotated space
        const unrotatedPointX = (pointX - rectX) * Math.cos(-rotation) - (pointY - rectY) * Math.sin(-rotation) + rectX;
        const unrotatedPointY = (pointX - rectX) * Math.sin(-rotation) + (pointY - rectY) * Math.cos(-rotation) + rectY;

        // Check if the unrotated point is inside the unrotated bounding box
        let minX = rectX + offsetX;
        let maxX = rectX + offsetX + width;
        let minY = rectY + offsetY;
        let maxY = rectY + offsetY + height;
        return unrotatedPointX >= minX && unrotatedPointX <= maxX && unrotatedPointY >= minY && unrotatedPointY <= maxY;
    };

    const handleMouseUp = (e) => {
        if (clickedSymbol && rotating) {
          const updatedCanvasSymbols = canvasSymbols.map(canvasSymbol =>
            canvasSymbol.uuid === clickedSymbol.uuid
              ? { ...canvasSymbol, rotation: currentRotation }
              : canvasSymbol
          );
          setCanvasSymbols(updatedCanvasSymbols);
          renderCanvas({type: "generic", clickedSymbol: clickedSymbol, symbols: updatedCanvasSymbols})
            setRotating(false)
        }
        else if (clickedSymbol && resizing) {
            const updatedCanvasSymbols = canvasSymbols.map(canvasSymbol =>
                canvasSymbol.uuid === clickedSymbol.uuid
                  ? calculateSymbolScale({...canvasSymbol, size: currentSize})
                  : canvasSymbol
              );
          setCanvasSymbols(updatedCanvasSymbols);
          renderCanvas({type: "generic", clickedSymbol: clickedSymbol, symbols: updatedCanvasSymbols})
            setResizing(false)
        }
        else if (clickedSymbol && moving) {
            const updatedCanvasSymbols = canvasSymbols.map(canvasSymbol =>
                canvasSymbol.uuid === clickedSymbol.uuid
                  ? calculateSymbolScale({...canvasSymbol, x: clickedSymbol.scaledX / (viewport.scale * containerWidth), y: clickedSymbol.scaledY / (viewport.scale * containerWidth)})
                  : canvasSymbol
              );
          setCanvasSymbols(updatedCanvasSymbols);
          renderCanvas({type: "generic", clickedSymbol: clickedSymbol, symbols: updatedCanvasSymbols})
            setMoving(false)
        }
        setMouseDown(false)
    };

    const handleCanvasClick = (e) => {
        const {x, y} = getMouseCoordinates(e);
        if (accordion === "symbols") {
            const newElement = {
                uuid: uuidv4(),
                layer: selectedLayer.uuid,
                x,
                y,
                size: symbolSize === "" ? 100 : symbolSize,
                symbol: selectedSymbol,
                rotation: 0,
            };
            const scaledNewElement = calculateSymbolScale(newElement);
            const updatedCanvasSymbols = [...canvasSymbols, scaledNewElement]
            setCanvasSymbols(updatedCanvasSymbols);
            renderCanvas({type: "generic", clickedSymbol: clickedSymbol, symbols: updatedCanvasSymbols})
        } else if (accordion === "select") {
            const scaledX = x * viewport.scale * containerWidth
            const scaledY = y * viewport.scale * containerWidth
            if (clickedSymbol) {
                const deleteMarkerX = clickedSymbol.scaledX
                const deleteMarkerY = clickedSymbol.scaledY
                const deleteMarkerWidth = boxSize
                const offsetX =  -(clickedSymbol.width / 2) - (boxSize / 2) + clickedSymbol.width + boxSize
                const offsetY =  -(clickedSymbol.height / 2) - (boxSize / 2) - boxSize
                if (
                    isPointInRotatedBox(
                    scaledX,
                    scaledY,
                    deleteMarkerX,
                    deleteMarkerY,
                    deleteMarkerWidth,
                    deleteMarkerWidth,
                    offsetX,
                    offsetY,
                    clickedSymbol.rotation
                )
                ) {
                    const updatedCanvasSymbols = canvasSymbols.filter(
                        canvasSymbol => canvasSymbol.uuid !== clickedSymbol.uuid
                    )
                    setCanvasSymbols(updatedCanvasSymbols);
                    renderCanvas({type: "generic", clickedSymbol: null, symbols: updatedCanvasSymbols})
                    setClickedSymbol(null)
                    return
                }
            }
            for (const canvasSymbol of canvasSymbols) {
                const halfwidth = canvasSymbol.width / 2
                const halfHeight = canvasSymbol.height / 2
                // Check if click hits symbol and symbol is on selected layer
                if (
                    scaledX >= canvasSymbol.scaledX  - halfwidth &&
                    scaledX <= canvasSymbol.scaledX + halfwidth
                    && scaledY >= canvasSymbol.scaledY  - halfHeight &&
                    scaledY <= canvasSymbol.scaledY  + halfHeight &&
                    canvasSymbol.layer === selectedLayer.uuid
                ) {
                    setClickedSymbol(canvasSymbol)
                    renderCanvas({type: "generic", clickedSymbol: canvasSymbol, symbols: canvasSymbols})
                    renderCanvas({type: "select", clickedSymbol: canvasSymbol, symbols: canvasSymbols})
                    return
                }
            }
            setClickedSymbol(null)
            renderCanvas({type: "generic", clickedSymbol: null, symbols: canvasSymbols})
        }
    };

    const drawDeleteMarker = (context, x, y, width, height) => {
        context.strokeStyle = theme.colors["violet"][8]
        context.lineWidth = 2;

        const rectX = -(width / 2) - (boxSize / 2) + width + boxSize;
        const rectY = -(height / 2) - (boxSize / 2) - boxSize;
        const rectWidth = boxSize;

        // Draw the rotation handle
        context.beginPath();
        context.moveTo(0, 0);
        context.rect(
            rectX,
            rectY,
            rectWidth,
            rectWidth,
        );

        context.moveTo(rectX, rectY);
        context.lineTo(rectX + rectWidth, rectY + rectWidth);

        context.moveTo(rectX, rectY + rectWidth);
        context.lineTo(rectX + rectWidth, rectY);

        context.stroke();
        context.closePath();
    }


    const drawRotationHandle = (context, x, y, width) => {
        // Draw the rotation handle
        context.beginPath();
        context.moveTo(0, - 1);
        context.lineTo((width / 2) + 20, - 1);
        context.lineTo((width / 2) + 20, - 5);
        context.lineTo((width / 2) + 25, - 10);
        context.lineTo((width / 2) + 30, - 5);
        context.lineTo((width / 2) + 30, 5);
        context.lineTo((width / 2) + 25, 10);
        context.lineTo((width / 2) + 20, 5);
        context.lineTo((width / 2) + 20, 1);
        context.lineTo(0, 1);
        context.closePath();

        context.fillStyle = theme.colors["violet"][8]
        context.fill();
    }

    const drawResizeHandle = (context, x, y, width, height, rotation) => {
        // Draw the rotation handle
        context.beginPath();
        context.moveTo(0, - 1);
        context.lineTo(-(width / 2) - 20, - 1);
        context.lineTo(-(width / 2) - 20, - 10);
        context.lineTo(-(width / 2) - 30, - 1);
        context.lineTo(-(width / 2) - 20, 10);
        context.lineTo(-(width / 2) - 20, 1);
        context.lineTo(0, 1);
        context.closePath();
        context.fillStyle = theme.colors["violet"][8]
        context.fill();
    }

    const recalculateSymbolsScale = () => {
        const updatedCanvasSymbols = canvasSymbols.map((canvasSymbol) => {
            return calculateSymbolScale(canvasSymbol)
        })
        setCanvasSymbols(updatedCanvasSymbols)
        renderCanvas({type: "generic", clickedSymbol: clickedSymbol, symbols: updatedCanvasSymbols})
    }

    const calculateSymbolScale = (canvasSymbol) => {
        const symbolImage = symbols.find((symbolImage) => symbolImage.file === canvasSymbol.symbol.file);
        const aspectRatio = symbolImage.image.width / symbolImage.image.height
        const scaledX = canvasSymbol.x * viewport.scale * containerWidth
        const scaledY = canvasSymbol.y * viewport.scale * containerWidth
        const width = aspectRatio <= 1 ? canvasSymbol.size * viewport.scale * containerWidth * aspectRatio : canvasSymbol.size * viewport.scale * containerWidth
        const height = aspectRatio >= 1 ? (canvasSymbol.size * viewport.scale * containerWidth) / aspectRatio : canvasSymbol.size * viewport.scale * containerWidth

        return {
            ...canvasSymbol,
            scaledX,
            scaledY,
            width,
            height,
        }
    }


    const renderCanvas = (render) => {
        const canvas = canvasRef.current;
        const bufferCanvas = bufferCanvasRef.current;

        const context = canvas.getContext('2d');
        const bufferContext = bufferCanvas.getContext('2d');
        if (render.type === "rotation" || render.type === "select" || render.type === "location" || render.type === "resize") {
            renderSelection(bufferContext, bufferCanvas, render.clickedSymbol)
        }
        else if (render.type === "generic") {
            // We need to use two canvases in order to hide the re-renders
            // Firstly, the buffer canvas should be cleared and re-rendered in the background
            // Once that's done, we can swap it with the visible canvas

            // Re-render size of canvas if scale changed
            canvas.width = bufferCanvas.width = containerWidth * widthMultiplier * viewport.scale
            canvas.height = bufferCanvas.height = containerWidth * widthMultiplier * viewport.scale

            // Clear the buffer canvas
            bufferContext.clearRect(0, 0, bufferCanvas.width, bufferCanvas.height);

            // Fetch the actually visible symbols (not in hidden layers) and exclude the clicked symbol (we'll draw that on the buffer canvas at the end)
            const visibleLayers = layers.filter((layer) => !layer.hidden).map((layer) => (layer.uuid))
            const visibleSymbols = render.symbols.filter(obj => visibleLayers.includes(obj.layer) && obj.uuid !== render.clickedSymbol?.uuid)

            // Find the preloaded image in the symbols state that matches with the file inside of visibleSymbols and draw it on to the buffer canvas
            for (const canvasSymbol of visibleSymbols) {
                const symbol = symbols.find((symbol) => symbol.file === canvasSymbol.symbol.file);
                bufferContext.save();
                bufferContext.translate(canvasSymbol.scaledX, canvasSymbol.scaledY)
                bufferContext.rotate(canvasSymbol.rotation);
                bufferContext.drawImage(symbol.image, - (canvasSymbol.width / 2), - (canvasSymbol.height / 2), canvasSymbol.width, canvasSymbol.height);
                bufferContext.restore();
            }

            // Replace the visible canvas with the newly rendered buffer canvas
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.drawImage(bufferCanvas, 0, 0);

            // Clear the buffer canvas
            bufferContext.clearRect(0, 0 , bufferCanvas.width, bufferCanvas.height);

            // If there's a clicked element, render it on the buffer canvas
            if (render.clickedSymbol) {
                renderSelection(bufferContext, bufferCanvas, render.clickedSymbol)
            }
        }

    }

    const renderSelection = (context, canvas, symbol) => {
        context.clearRect(0, 0 , canvas.width, canvas.height);

        context.save();
        context.translate(symbol.scaledX, symbol.scaledY)
        context.rotate(symbol.rotation);

        context.beginPath();
        context.strokeStyle = theme.colors["violet"][8]
        context.lineWidth = 2;
        context.rect(
            -(symbol.width / 2) - (boxSize / 2),
            -(symbol.height / 2) - (boxSize / 2),
            symbol.width + boxSize,
            symbol.height + boxSize
        );
        const symbolImage = symbols.find((symbolImage) => symbolImage.file === symbol.symbol.file);
        context.drawImage(symbolImage.image, - symbol.width / 2, - symbol.height / 2, symbol.width, symbol.height);
        context.stroke();
        drawRotationHandle(context, symbol.scaledX, symbol.scaledY, symbol.width);
        drawResizeHandle(context, symbol.scaledX, symbol.scaledY, symbol.width);
        drawDeleteMarker(context, symbol.scaledX, symbol.scaledY, symbol.width, symbol.height)
        context.restore();
    }

    const handleScroll = () => {
        // Cancel any previous animation frame requests
        cancelAnimationFrame(requestIdRef.current);
        // Use requestAnimationFrame for optimized rendering
        requestIdRef.current = requestAnimationFrame(() => {
            const container = containerRef.current;
            const rect = container.getBoundingClientRect();
            const offsetTop = rect.top;
            const offsetLeft = rect.left;

            setViewport((prevViewport) => ({
                x: container.scrollLeft - offsetLeft,
                y: container.scrollTop - offsetTop,
                scale: prevViewport.scale,
            }));
        });
    };

    useEffect(() => {
        handleScroll()
    }, [])

    const handleResize = () => {
        if (containerRef.current) {
            const newWidth = containerRef.current.clientWidth;
            setContainerWidth(newWidth / widthMultiplier);
        }
    };

    useEffect(() => {
        // Set the initial width
        if (containerRef.current) {
            setContainerWidth(containerRef.current.clientWidth / widthMultiplier);
        }

        // Listen for window resize events
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
    }, [containerWidth]);

    useEffect(() => {
        if (clickedSymbol) {
            renderCanvas({type: "location", clickedSymbol: clickedSymbol, symbols: canvasSymbols})
        }
    }, [currentLocation])

    useEffect(() => {
        if (clickedSymbol) {
            renderCanvas({type: "rotation", clickedSymbol: clickedSymbol, symbols: canvasSymbols})
        }
    }, [currentRotation])

        useEffect(() => {
        if (clickedSymbol) {
            renderCanvas({type: "resize", clickedSymbol: clickedSymbol, symbols: canvasSymbols})
        }
    }, [currentSize])

    useEffect(() => {
        renderCanvas({type: "generic", clickedSymbol: clickedSymbol, symbols: canvasSymbols})
    }, [viewport.scale, layers, containerWidth]);

    useEffect(() => {
        recalculateSymbolsScale();
    }, [viewport.scale, containerWidth]);

    useEffect(() => {
        const canvas = canvasRef.current;
        canvas.addEventListener('click', handleCanvasClick);
        canvas.addEventListener('mousedown', handleMouseDown);
        canvas.addEventListener('mousemove', handleMouseMove);
        canvas.addEventListener('mouseup', handleMouseUp);

        const bufferCanvas = bufferCanvasRef.current;
        bufferCanvas.addEventListener('click', handleCanvasClick);
        bufferCanvas.addEventListener('mousedown', handleMouseDown);
        bufferCanvas.addEventListener('mousemove', handleMouseMove);
        bufferCanvas.addEventListener('mouseup', handleMouseUp);

        return () => {
            canvas.removeEventListener('click', handleCanvasClick);
            canvas.removeEventListener('mousedown', handleMouseDown);
            canvas.removeEventListener('mousemove', handleMouseMove);
            canvas.removeEventListener('mouseup', handleMouseUp);

            bufferCanvas.removeEventListener('click', handleCanvasClick);
            bufferCanvas.removeEventListener('mousedown', handleMouseDown);
            bufferCanvas.removeEventListener('mousemove', handleMouseMove);
            bufferCanvas.removeEventListener('mouseup', handleMouseUp);
        };
    }, [viewport, selectedSymbol, symbolSize, layers, selectedLayer, accordion, canvasSymbols, mouseDown, clickedSymbol, currentRotation, currentLocation, mouseMode, containerWidth, currentSize]);

    return (
        <div
            style={{
                width: "100%",
                maxHeight: "100%",
                maxWidth: "100%",
                aspectRatio: "1",
                position: "relative",
                overflow: "auto",
                backgroundColor: "#ffffff",
            }} ref={containerRef}
            onScroll={handleScroll}
        >
            <canvas
                ref={bufferCanvasRef}
                style={{position: 'absolute'}}
            />
            <canvas
                ref={canvasRef}
            />
        </div>

    );
};

export default SymbolPatternCanvas;
