import React, {useState} from 'react';
import {Button, Grid, NumberInput, Stack, TextInput} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import {createCell} from "../../Utils/cell";

const AlphaPatternBlank = () => {
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [width, setWidth] = useState(20);
    const [height, setHeight] = useState(20)

    const confirmCreate = () => {
        const gridData = Array.from({ length: height }, (_, y) =>
            Array.from({ length: width }, (_, x) => createCell(x, y, "#FFFFFF"))
        );
        navigate('/alphapattern/edit/',
            {
                state: {
                    imageGridData: gridData,
                    name: name,
                    rowDirection: "VERTICAL",
                    rowStarting: "TOP_LEFT",
                    rowClockwise: true,
                    rowSwitching: false,
                    currentRow: 0,
                    currentCell: 0,
        }});
    }


    return (
    <React.Fragment>
      <Grid
        justify="center"
        align="center"
        columns={24}
        mih="100vh"
        h="100vh"
        mah="100vh"
      >
        <Grid.Col span={18} mih={"85vh"} h={"85vh"} mah={"85vh"} mt={"10vh"}>
          <Stack
              h={"100%"}
              align="stretch"
              justify="center"
              gap="md"
          >
            <TextInput
                w={"100%"}
              label="Name"
              value={name}
              onChange={(event) => setName(event.currentTarget.value)}
              maxLength={64}
            />
              <NumberInput
                                    w={"100%"}

                  label="Width"
                value={width}
                onChange={setWidth}
                allowNegative={false}
                clampBehavior="strict"
                min={1}
                max={1000}
            />
              <NumberInput
                  w={"100%"}
                  label={"Height"}
                value={height}
                onChange={setHeight}
                allowNegative={false}
                clampBehavior="strict"
                min={1}
                max={1000}
            />
            <Button
                fullWidth
              disabled={name === ""}
              onClick={() => { confirmCreate() }}
            >
              Confirm
            </Button>
          </Stack>
        </Grid.Col>
      </Grid>
    </React.Fragment>
    );
}

export default AlphaPatternBlank;
