import React from 'react';
import {Outlet} from "react-router-dom";


const AlphaPatternCreateShell = () => {

    return (
        <Outlet/>
    );
}

export default AlphaPatternCreateShell;
