import {Badge, Center, Group, rem} from "@mantine/core";
import {IconLetterX, IconLetterY} from "@tabler/icons-react";
import React from "react";

const Coordinates = (
    {
        coordinates,
    }) => {

    return (
                                    <Center>
                                <Group>
                                    <Badge leftSection={<IconLetterX
                                        style={{width: rem(12), height: rem(12)}}/>}>{coordinates.x}</Badge>
                                    <Badge leftSection={<IconLetterY
                                        style={{width: rem(12), height: rem(12)}}/>}>{coordinates.y}</Badge>
                                </Group>
                            </Center>
    )

}

export default Coordinates;