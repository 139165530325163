import {ActionIcon, Center, Divider, rem, ScrollArea, Stack} from "@mantine/core";
import {IconMinus, IconPlus} from "@tabler/icons-react";
import React from "react";
import {createCell} from "../../Utils/cell";

const PaintCanvas = (
    {
        gridData,
        setGridData,
        gridHeight,
        setGridHeight,
        gridWidth,
        setGridWidth,
        lines,
        setLines,
    }) => {

    const updatePaintComponents = (xDiff, yDiff) => {
        const updatedLines = lines.map(component => {
            const updatedFromX = component.from.x + xDiff;
            const updatedToX = component.to.x + xDiff;
            const updatedFromY = component.from.y + yDiff;
            const updatedToY = component.to.y + yDiff;

            const isBelowZero = [updatedFromX, updatedToX, updatedFromY, updatedToY].some(value => value < 0);

            if (!isBelowZero) {
                return {
                    from: {x: updatedFromX, y: updatedFromY},
                    to: {x: updatedToX, y: updatedToY}
                };
            }

            return null;
        }).filter(component => component !== null);

        setLines(updatedLines)
    };

    const addTopCells = () => {
        const newEmptyRow = Array.from({length: gridWidth}, (_, index) => (createCell(index, 0)));
        const updatedExistingGridData = gridData.map((row) => {
            return row.map((cell) => {
                return createCell(cell.x, cell.y + 1, cell.color)
            })
        })
        const updatedGridData = [newEmptyRow, ...updatedExistingGridData];
        setGridData(updatedGridData);
        setGridHeight(gridHeight + 1);
        updatePaintComponents(0, 1)
    };

    const addBottomCells = () => {
        const newEmptyRow = Array.from({length: gridWidth}, (_, index) => (createCell(index, gridHeight)));
        const updatedGridData = [...gridData, newEmptyRow];
        setGridData(updatedGridData);
        setGridHeight(gridHeight + 1)
    };

    const addLeftCells = () => {
        const updatedGridData = gridData.map((row, rowIndex) => [
            createCell(0, rowIndex),
            ...row.map(cell => createCell(cell.x + 1, cell.y, cell.color))
        ]);
        setGridData(updatedGridData);
        setGridWidth(gridWidth + 1)
        updatePaintComponents(1, 0)
    };

    const addRightCells = () => {
        const updatedGridData = gridData.map((row, rowIndex) => [
            ...row,
            createCell(gridWidth, rowIndex),
        ]);
        setGridData(updatedGridData);
        setGridWidth(gridWidth + 1)
    }

    const removeTopCells = () => {
        const updatedGridData = gridData.map((row) => {
            return row.map((cell) => {
                return createCell(cell.x, cell.y - 1, cell.color)
            })
        }).slice(1)
        setGridData(updatedGridData);
        setGridHeight(gridHeight - 1)
        updatePaintComponents(0, -1)
    };

    const removeLeftCells = () => {
        const updatedGridData = gridData.map((row) => {
            return row.map((cell) => {
                return createCell(cell.x - 1, cell.y, cell.color)
            }).slice(1)
        })
        setGridData(updatedGridData);
        setGridWidth(gridWidth - 1)
        updatePaintComponents(-1, 0)
    };

    const removeRightCells = () => {
        const updatedGridData = gridData.map(row => row.slice(0, -1));
        setGridData(updatedGridData);
        setGridWidth(gridWidth - 1)
    };

    const removeBottomCells = () => {
        const updatedGridData = gridData.slice(0, -1);
        setGridData(updatedGridData);
        setGridHeight(gridHeight - 1)
    };

    return (
        <Stack>
                <Divider label="Top" labelPosition="center"/>
                <Center>
                    <ActionIcon.Group>
                        <ActionIcon variant="default" size="sm" aria-label="Add-Top-Cells"
                                    disabled={gridHeight === 1000} onClick={addTopCells}>
                            <IconPlus style={{width: rem(20)}} stroke={1.5}/>
                        </ActionIcon>
                        <ActionIcon variant="default" size="sm" aria-label="Remove-Top-Cells"
                                    disabled={gridHeight === 1} onClick={removeTopCells}>
                            <IconMinus style={{width: rem(20)}} stroke={1.5}/>
                        </ActionIcon>
                    </ActionIcon.Group>
                </Center>
                <Divider label="Left" labelPosition="center"/>
                <Center>
                    <ActionIcon.Group>
                        <ActionIcon variant="default" size="sm" aria-label="Add-Left-Cells"
                                    disabled={gridWidth === 1000} onClick={addLeftCells}>
                            <IconPlus style={{width: rem(20)}} stroke={1.5}/>
                        </ActionIcon>
                        <ActionIcon variant="default" size="sm" aria-label="Remove-Left-Cells"
                                    disabled={gridWidth === 1} onClick={removeLeftCells}>
                            <IconMinus style={{width: rem(20)}} stroke={1.5}/>
                        </ActionIcon>
                    </ActionIcon.Group>
                </Center>
                <Divider label="Right" labelPosition="center"/>
                <Center>
                    <ActionIcon.Group>
                        <ActionIcon variant="default" size="sm" aria-label="Add-Right-Cells"
                                    disabled={gridWidth === 1000} onClick={addRightCells}>
                            <IconPlus style={{width: rem(20)}} stroke={1.5}/>
                        </ActionIcon>
                        <ActionIcon variant="default" size="sm" aria-label="Remove-Right-Cells"
                                    disabled={gridWidth === 1} onClick={removeRightCells}>
                            <IconMinus style={{width: rem(20)}} stroke={1.5}/>
                        </ActionIcon>
                    </ActionIcon.Group>
                </Center>
                <Divider label="Bottom" labelPosition="center"/>
                <Center>
                    <ActionIcon.Group>
                        <ActionIcon variant="default" size="sm" aria-label="Add-Bottom-Cells"
                                    disabled={gridHeight === 1000} onClick={addBottomCells}>
                            <IconPlus style={{width: rem(20)}} stroke={1.5}/>
                        </ActionIcon>
                        <ActionIcon variant="default" size="sm" aria-label="Remove-Bottom-Cells"
                                    disabled={gridHeight === 1} onClick={removeBottomCells}>
                            <IconMinus style={{width: rem(20)}} stroke={1.5}/>
                        </ActionIcon>
                    </ActionIcon.Group>
                </Center>
        </Stack>
    )

}

export default PaintCanvas;