import React, {useContext, useEffect, useRef, useState} from "react";
import {makeAuthenticatedRequest} from "../../Utils/authenticated_request";
import {
    ActionIcon, Alert,
    AspectRatio,
    Button,
    Card,
    Grid,
    Group,
    Image,
    Overlay,
    rem,
    ScrollArea,
    Stack,
    TextInput,
    Title,
    Tooltip,
    useMantineTheme
} from '@mantine/core';
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {createCell} from "../../Utils/cell";
import {IconInfoCircle, IconLock, IconPlus, IconSearch, IconX} from "@tabler/icons-react";
import {UserContext} from "../../Context/User";
import Footer from "../Footer";


const AlphaPatternManager = (
    {}) => {
    const navigate = useNavigate();
    const theme = useMantineTheme();
    const {user} = useContext(UserContext);
    const canvasRef = useRef(null);

    const [alphaPatterns, setAlphaPatterns] = React.useState([])
    const [alphaPatternSearch, setAlphaPatternSearch] = useState('');
    const [displayedAlphaPatterns, setDisplayedAlphaPatterns] = useState(alphaPatterns);

    const isButtonDisabled = user?.tier !== "PREMIUM" && alphaPatterns.length > 3;

    const fetchAlphaPatterns = async () => {
        try {
            const response = await makeAuthenticatedRequest(
                `${process.env.REACT_APP_API_HOST}/alpha_pattern/`,
                {redirect: false}
            );

            if (response.ok) {
                const jsonData = await response.json();
                setAlphaPatterns(jsonData.results)
                setDisplayedAlphaPatterns(jsonData.results)
            } else {
                console.error("Request failed with status:", response.status);
                setAlphaPatterns([])
                setDisplayedAlphaPatterns([])
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

        const deleteAlphaPattern = async (alphaPattern) => {
        try {
            const response = await makeAuthenticatedRequest(
                `${process.env.REACT_APP_API_HOST}/alpha_pattern/${alphaPattern.uuid}/`,
                {method: 'DELETE'},
            );

            if (response.ok) {
                await fetchAlphaPatterns()
            } else {
                console.error("Request failed with status:", response.status);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const editAlphaPattern = (alphaPattern, alphaPatternIndex) => {
        if (user?.tier !== "PREMIUM" && alphaPatternIndex >= 3) {
            return
        }
        const image = new window.Image();
        image.crossOrigin = "Anonymous";  // Set the crossOrigin attribute to handle CORS
        image.src = alphaPattern.file;
        image.onload = () => {
            const gridData = processImage(image);
            navigate('/alphapattern/edit/', {
                state: {
                    imageGridData: gridData,
                    name: alphaPattern.name,
                    uuid: alphaPattern.uuid,
                    rowDirection: alphaPattern.row_direction,
                    rowStarting: alphaPattern.row_starting,
                    rowClockwise: alphaPattern.row_clockwise,
                    rowSwitching: alphaPattern.row_switching,
                    currentRow: alphaPattern.current_row,
                    currentCell: alphaPattern.current_cell,
                }
            });
        };
    }

    const processImage = (image) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);

        const imageData = ctx.getImageData(0, 0, image.width, image.height);
        const data = imageData.data;

        const matrix = [];
        for (let y = 0; y < image.height; y++) {
          const row = [];
          for (let x = 0; x < image.width; x++) {
            const index = (y * image.width + x) * 4;
            const r = data[index];
            const g = data[index + 1];
            const b = data[index + 2];
            const color = `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
            row.push(createCell(x, y, color));
          }
          matrix.push(row);
        }
        return matrix
    };

    const handleDeleteSearch = () => {
        setAlphaPatternSearch("")
        setDisplayedAlphaPatterns(alphaPatterns)
    }

    const handleAlphaPatternSearch = (event) => {
        setAlphaPatternSearch(event.currentTarget.value)


        const filteredAlphaPatterns = alphaPatterns.filter((alphaPattern) =>
            alphaPattern.name.toLowerCase().includes(event.currentTarget.value.toLowerCase())
        );

        if (filteredAlphaPatterns !== displayedAlphaPatterns) {
            setDisplayedAlphaPatterns(filteredAlphaPatterns);
        }
    };

    useEffect(() => {
        fetchAlphaPatterns()
    }, []);

    return (
        <React.Fragment>
            <canvas ref={canvasRef} style={{display: 'none'}}/>
            <Grid grow justify="center" align="center" columns={24}>
                <Grid.Col span={3}>

                </Grid.Col>
                <Grid.Col span={18}>
                    <Stack pt="15vh" direction="column" style={{minHeight: "85vh", height: "85vh"}}>
                        {isButtonDisabled ? (<Tooltip
                            label={"Max 3 allowed. Either re-activate Premium or delete some items."}
                        >
                            <Button p={20} disabled fullWidth size="xl" leftSection={<IconPlus size={20}/>}
                                    component={RouterLink}
                                    to="create">Create New</Button>
                        </Tooltip>) : (<Button p={20} disabled={alphaPatterns.length >= 100} fullWidth size="xl" leftSection={<IconPlus size={20}/>}
                                               component={RouterLink}
                                               to="create">Create New</Button>)}
                        <TextInput
                            leftSection={<IconSearch style={{width: rem(16), height: rem(16)}}/>}
                            leftSectionPointerEvents="none"
                            rightSection={alphaPatternSearch ? <IconX onClick={handleDeleteSearch} style={{
                                width: rem(16),
                                height: rem(16)
                            }}/> : null}
                            aria-label="Search Alpha Patterns"
                            value={alphaPatternSearch}
                            onChange={(event) => handleAlphaPatternSearch(event)}
                        />
                        <ScrollArea
                                    style={{flexGrow: 1}}>

                            <Grid justify="flex-start" align="start">
                                {!user &&
                                    <Alert title="Not Signed In" icon={<IconInfoCircle />}>
                                      You're not signed in, so you won't be able to save any pattern you create.
                                    </Alert>
                                }
                                {displayedAlphaPatterns.map((alphaPattern, alphaPatternIndex) => {
                                    return (
                                        <Grid.Col key={alphaPattern.uuid} span={{xl: 3, lg: 4, md: 6, sm: 12}}>
                                            <Card shadow="sm" padding="lg" radius="md" withBorder
                                                  onClick={() => editAlphaPattern(alphaPattern, alphaPatternIndex)}>
                                                <Card.Section p={"md"}>
                                                    <AspectRatio ratio={16 / 9} mx="auto" pos="relative">
                                                        <Image
                                                            radius="md"
                                                            src={alphaPattern.file}
                                                            crossOrigin="Anonymous"
                                                        />
                                                        {(user?.tier !== "PREMIUM" && alphaPatternIndex >= 3) &&
                                                            <Overlay color="#000" backgroundOpacity={0.85}/>}
                                                    </AspectRatio>
                                                </Card.Section>
                                                <Group justify="space-between" mt="md" mb="xs">
                                                    <div></div>
                                                    <Title fw={500} order={4}>
                                                        {(user?.tier !== "PREMIUM" && alphaPatternIndex >= 3) &&
                                                            <Tooltip
                                                                label={"Max 3 allowed. Either re-activate Premium or delete some items."}><IconLock/></Tooltip>}
                                                        {alphaPattern.name}
                                                    </Title>
                                                    <Tooltip label="Delete Alpha Pattern">
                                                        <ActionIcon
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                deleteAlphaPattern(alphaPattern)
                                                            }}
                                                        >
                                                            <IconX size={20}/>
                                                        </ActionIcon>
                                                    </Tooltip>
                                                </Group>
                                            </Card>
                                        </Grid.Col>
                                    )
                                })}
                            </Grid>
                        </ScrollArea>
                    </Stack>


                </Grid.Col>
                <Grid.Col span={3}>

                </Grid.Col>
            </Grid>
            <Footer/>
        </React.Fragment>
    )

}

export default AlphaPatternManager;