import React from 'react';
import {AppShell, BackgroundImage, Center} from "@mantine/core";
import mySvg from "../Images/crochetDesign.svg";
import Copyright from "./Authentication/Copyright";


const Footer = ({

                  }) => {

    return (
        <AppShell.Footer>
            <BackgroundImage src={mySvg} style={{height: "100%", backgroundPosition: "center -100%", borderImage: "fill 0 linear-gradient(#0003, #000)"}}>
                <Center>
                    <Copyright style={{ zIndex: 2 }} color={"white"}/>
                </Center>
            </BackgroundImage>

        </AppShell.Footer>
    );
}

export default Footer;