import React, { useState, useEffect } from 'react';
import {
  Button,
  Center,
  CopyButton,
  Divider, getThemeColor,
  LoadingOverlay,
  Modal,
  ScrollArea,
  Textarea,
  TextInput, useMantineTheme
} from "@mantine/core";
import { IconCircleFilled } from "@tabler/icons-react";
import {getUniqueColorsWithCount} from "../../Utils/grid";

const FullPatternModal = ({
  fullPatternModalOpened,
  fullPatternModalClose,
  isMobile,
  fullPatternText,
  setFullPatternText,
  gridData,
  handleRowCount,
  buildRowPattern,
  rowRange
}) => {
      const theme = useMantineTheme();

  const [topColors, setTopColors] = useState(getUniqueColorsWithCount(gridData));
  const [loadingFullPattern, setLoadingFullPattern] = useState(false);

const handleFullPattern = async () => {
  const fullPattern = [];
  for (let i = 0; i < rowRange[1] + 1; i++) {
    const { updatedCurrentCells, updatedGridData } = handleRowCount(1, i);
    const currentRowPattern = buildRowPattern(updatedCurrentCells);
    const currentRowText = convertRowToText(currentRowPattern);
    fullPattern.push(`ROW ${i + 1}: ${currentRowText}`);

    // Yield control back to the event loop to allow re-render
    await new Promise(resolve => setTimeout(resolve, 0));
  }
  setFullPatternText(fullPattern.join('\n\n'));
};


  const handleConfirmPattern = () => {
    setLoadingFullPattern(true);

    handleFullPattern()
      .finally(() => {
        setLoadingFullPattern(false);
      });
  };

  const convertRowToText = (row) => {
    const textRow = row.map(color => {
      const index = topColors.findIndex((obj) => obj.originalColorName === color.name);
      return `${color.count} x ${topColors[index].newColorName}`;
    }).join(', ');
    return textRow;
  };

  const changeColorName = (newColorName, id) => {
    setTopColors((prevData) => {
      const newData = [...prevData];
      newData[id] = { ...newData[id], newColorName };
      return newData;
    });
  };

  return (
    <React.Fragment>
      <Modal
        size={"xl"}
        opened={fullPatternModalOpened}
        onClose={fullPatternModalClose}
        title="Generate Pattern"
        fullScreen={isMobile}
        transitionProps={{ transition: 'fade', duration: 200 }}
      >
        <Divider my="md"/>
        <React.Fragment>
          <ScrollArea h={"20vh"} style={{border: `3px solid ${getThemeColor('violet', theme)}`}}>
            {topColors.map((block) => (
              <Center key={block.id}>
                <TextInput
                  value={topColors[block.id].newColorName}
                  onChange={(event) => changeColorName(event.currentTarget.value, block.id)}
                  leftSectionPointerEvents="none"
                  leftSection={<IconCircleFilled style={{ color: block.color }}/>}
                  label={`Count: ${block.count}`}
                  placeholder={block.originalColorName}
                />
              </Center>
            ))}
          </ScrollArea>
          <Divider my="md"/>
          <Center>
            <Button
              onClick={() => {
                handleConfirmPattern();
              }}
              disabled={loadingFullPattern}
              justify="center"
            >
              {loadingFullPattern ? "Loading" : "Confirm"}
            </Button>
          </Center>
          <Divider my="md"/>
          <ScrollArea h={"30vh"}>
            <Textarea
              placeholder="Your generated pattern goes here"
              autosize
              aria-label="Pattern Text"
              value={fullPatternText}
              onChange={(event) => setFullPatternText(event.currentTarget.value)}
            />
          </ScrollArea>
          <Divider my="md"/>
          <Center>
            <CopyButton value={fullPatternText}>
              {({ copied, copy }) => (
                <Button onClick={copy}>
                  {copied ? 'Copied Pattern' : 'Copy Pattern'}
                </Button>
              )}
            </CopyButton>
          </Center>
        </React.Fragment>
      </Modal>
    </React.Fragment>
  );
};

export default FullPatternModal;
