import {
    ActionIcon,
    Badge,
    Center,
    Divider,
    getThemeColor,
    NumberInput,
    ScrollArea,
    Space,
    Stack,
    Tooltip,
    useMantineTheme
} from "@mantine/core";
import React, {useEffect, useRef, useState} from "react";
import FullPatternModal from "./FullPatternModal";
import {isColorLight, nearest} from "../../Utils/grid";
import {IconCaretDownFilled, IconCaretUpFilled, IconWriting} from "@tabler/icons-react";


const CountCanvas = (
    {
        accordion,
        currentRow,
        setCurrentRow,
        rowRange,
        setRowRange,
        cellRange,
        setCellRange,
        currentRowPattern,
        setCurrentRowPattern,
        currentCells,
        setCurrentCells,
        rowDirection,
        gridData,
        setGridData,
        gridHeight,
        gridWidth,
        rowStarting,
        rowSwitching,
        currentCell,
        setCurrentCell,
        countCell,
        setCountCell,
        rowClockwise,
        fullPatternModalOpened,
        fullPatternModalOpen,
        fullPatternModalClose,
        isMobile,
        fullPatternText,
        setFullPatternText,
        locationState
    }) => {
    const theme = useMantineTheme();
    const prevCurrentRow = useRef(currentRow);



    const [spiralBoundary, setSpiralBoundary] = useState({
        top: Math.floor((gridHeight - 1) / 2),
        bottom: Math.ceil((gridHeight - 1)/ 2),
        left: Math.floor((gridWidth - 1) / 2),
        right: Math.ceil((gridWidth - 1) / 2),
    })

    const buildRowPattern = (cells) => {
        let rowPattern = [];
        let currentColor = cells[0].color
        let colorCount = 0;

        for (const obj of cells) {
            if (obj.color === currentColor) {
                colorCount++;
            } else {
                rowPattern.push({count: colorCount, name: nearest(currentColor).name, color: currentColor})
                currentColor = obj.color;
                colorCount = 1;
            }
        }
        if (colorCount !== 1) {
            rowPattern.push({count: colorCount, name: nearest(currentColor).name, color: currentColor})
        }

        return rowPattern
    }

    const handleRowCount = (direction, currentRow = 0) => {
        if (rowDirection === "VERTICAL") {
            const data = countVertical(currentRow)
            setCellRange([0, data.updatedCurrentCells.length - 1])
            return data
        }
        if (rowDirection === "HORIZONTAL") {
            const data = countHorizontal(currentRow)
            setCellRange([0, data.updatedCurrentCells.length - 1])
            return data
        }
        if (rowDirection === "DIAGONAL") {
            const data = countDiagonal(currentRow)
            setCellRange([0, data.updatedCurrentCells.length - 1])
            return data
        }
        if (rowDirection === "SPIRAL") {
            const data = countSpiral(currentRow, direction)
            setCellRange([0, data.updatedCurrentCells.length - 1])
            return data
        }
    }

    const countVertical = (updatedCurrentRow) => {
        const updatedCurrentCells = [];
        const updatedGridData = JSON.parse(JSON.stringify(gridData));
        const rowStartingCols = {
            TOP_LEFT: updatedCurrentRow,
            TOP_RIGHT: updatedCurrentRow,
            BOTTOM_LEFT: (-1 * updatedCurrentRow) + gridHeight - 1,
            BOTTOM_RIGHT: (-1 * updatedCurrentRow) + gridHeight - 1,
        }
        for (let rIndex = 0; rIndex < gridHeight; rIndex++) {
            if (rowStartingCols[rowStarting] === rIndex) {
                updatedCurrentCells.push(...updatedGridData[rIndex])
                for (let cIndex = 0; cIndex < gridWidth; cIndex++) {
                    updatedGridData[rIndex][cIndex] = {...updatedGridData[rIndex][cIndex], highlighted: true};
                }
            } else {
                for (let cIndex = 0; cIndex < gridWidth; cIndex++) {
                    updatedGridData[rIndex][cIndex] = {
                        ...updatedGridData[rIndex][cIndex],
                        selected: false,
                        highlighted: false
                    };
                }
            }
        }
        setGridData(updatedGridData);

        if (rowSwitching && updatedCurrentRow % 2 !== 0) {
            updatedCurrentCells.reverse();
        }
        if (rowStarting === "TOP_LEFT" || rowStarting === "BOTTOM_LEFT") {
            setCurrentCells(updatedCurrentCells)
        } else {
            updatedCurrentCells.reverse()
            setCurrentCells(updatedCurrentCells)
        }
        return {updatedCurrentCells, updatedGridData}
    }

    const countHorizontal = (updatedCurrentRow) => {
        const updatedCurrentCells = [];
        const updatedGridData = JSON.parse(JSON.stringify(gridData));
        const rowStartingCols = {
            topLeft: updatedCurrentRow,
            topRight: (-1 * updatedCurrentRow) + gridWidth - 1,
            bottomLeft: updatedCurrentRow,
            bottomRight: (-1 * updatedCurrentRow) + gridWidth - 1,
        }
        for (let rIndex = 0; rIndex < gridHeight; rIndex++) {
            for (let cIndex = 0; cIndex < gridWidth; cIndex++) {
                if (rowStartingCols[rowStarting] === cIndex) {
                    updatedCurrentCells.push(updatedGridData[rIndex][cIndex])
                    updatedGridData[rIndex][cIndex] = {...updatedGridData[rIndex][cIndex], highlighted: true};
                } else {
                    updatedGridData[rIndex][cIndex] = {
                        ...updatedGridData[rIndex][cIndex],
                        selected: false,
                        highlighted: false
                    };
                }
            }
        }
        setGridData(updatedGridData);

        if (rowSwitching && updatedCurrentRow % 2 !== 0) {
            updatedCurrentCells.reverse();
        }
        if (rowStarting === "TOP_LEFT" || rowStarting === "TOP_RIGHT") {
            setCurrentCells(updatedCurrentCells)
        } else {
            updatedCurrentCells.slice().reverse()
            setCurrentCells(updatedCurrentCells)
        }
        return {updatedCurrentCells, updatedGridData}
    }

    const countDiagonal = (updatedCurrentRow) => {
        const updatedCurrentCells = [];
        const updatedGridData = JSON.parse(JSON.stringify(gridData));
        const diagonalDirection = (rowStarting === "TOP_RIGHT" || rowStarting === "BOTTOM_LEFT") ? 1 : -1
        const rowStartingCols = {
            topLeft: updatedCurrentRow,
            topRight: updatedCurrentRow - gridWidth + 1,
            bottomLeft: (-1 * updatedCurrentRow) + gridHeight - 1,
            bottomRight: (-1 * updatedCurrentRow) + gridWidth + gridHeight - 2,
        }
        for (let rIndex = 0; rIndex < gridHeight; rIndex++) {
            for (let cIndex = 0; cIndex < gridWidth; cIndex++) {
                const rowCol = rIndex - cIndex * diagonalDirection;
                if (rowStartingCols[rowStarting] === rowCol) {
                    updatedCurrentCells.push(updatedGridData[rIndex][cIndex])
                    updatedGridData[rIndex][cIndex] = {...updatedGridData[rIndex][cIndex], highlighted: true};
                } else {
                    updatedGridData[rIndex][cIndex] = {
                        ...updatedGridData[rIndex][cIndex],
                        selected: false,
                        highlighted: false
                    };
                }
            }
        }
        setGridData(updatedGridData);

        if (rowSwitching && updatedCurrentRow % 2 !== 0) {
            updatedCurrentCells.reverse();
        }
        setCurrentCells(updatedCurrentCells)
        return {updatedCurrentCells, updatedGridData}
    }

const countSpiral = (updatedCurrentRow, direction) => {
  const updatedCurrentCells = [];
  const updatedGridData = JSON.parse(JSON.stringify(gridData));
  let { top, bottom, left, right } = spiralBoundary;
  // Update the boundaries for the next ring
  top = top - direction;
  bottom = bottom + direction;
  left = left - direction;
  right = right + direction;

  // Separate cells based on the side of the box
  const topSide = [];
  const bottomSide = [];
  const leftSide = [];
  const rightSide = [];

  for (let rIndex = 0; rIndex < gridHeight; rIndex++) {
    for (let cIndex = 0; cIndex < gridWidth; cIndex++) {
      const isOnTop = rIndex === top && cIndex >= left && cIndex <= right;
      const isOnBottom = rIndex === bottom && cIndex >= left && cIndex <= right;
      const isOnLeft = cIndex === left && rIndex >= top && rIndex <= bottom;
      const isOnRight = cIndex === right && rIndex >= top && rIndex <= bottom;

      if (isOnTop) {
          updatedGridData[rIndex][cIndex] = { ...updatedGridData[rIndex][cIndex], highlighted: true };
        topSide.push(updatedGridData[rIndex][cIndex]);
      } else if (isOnBottom) {
                  updatedGridData[rIndex][cIndex] = { ...updatedGridData[rIndex][cIndex], highlighted: true };

        bottomSide.push(updatedGridData[rIndex][cIndex]);
      } else if (isOnLeft) {
                  updatedGridData[rIndex][cIndex] = { ...updatedGridData[rIndex][cIndex], highlighted: true };

        leftSide.push(updatedGridData[rIndex][cIndex]);
      } else if (isOnRight) {
                  updatedGridData[rIndex][cIndex] = { ...updatedGridData[rIndex][cIndex], highlighted: true };

        rightSide.push(updatedGridData[rIndex][cIndex]);
      } else {
        updatedGridData[rIndex][cIndex] = {
          ...updatedGridData[rIndex][cIndex],
          selected: false,
          highlighted: false,
        };
      }
    }
  }

  // Combine cells in a clockwise order
  updatedCurrentCells.push(...topSide);
  updatedCurrentCells.push(...rightSide);
  updatedCurrentCells.push(...bottomSide.reverse());
  updatedCurrentCells.push(...leftSide.reverse());

  setGridData(updatedGridData);

  if (!rowClockwise) {
      updatedCurrentCells.reverse();
  }

  if (rowSwitching && updatedCurrentRow % 2 !== 0) {
    updatedCurrentCells.reverse();
  }

  setCurrentCells(updatedCurrentCells);

  // Update the state with the new boundaries
  setSpiralBoundary({ top, bottom, left, right });
  return { updatedCurrentCells, updatedGridData };
};

    const handleOpenModal = () => {
        fullPatternModalOpen()
        setFullPatternText('')
    }


    const handleCurrentRow = (currentRow, e) => {
        setCurrentRow((e === "" || e < 0) ? currentRow : e)
    }

    const handleCurrentCell = (currentCells, gridData, currentCell, e) => {
        // If e or currentCell are empty string (backspace on number), set to 0
        const diff = e === "" || e < 0 ? 0 : e - currentCell
        const updatedGridData = JSON.parse(JSON.stringify(gridData));
        const oldCell = currentCells[currentCell]
        const newCell = currentCells[currentCell + diff]

        updatedGridData[oldCell.y][oldCell.x] = {
            ...updatedGridData[oldCell.y][oldCell.x],
            highlighted: true,
            selected: false
        }
        updatedGridData[newCell.y][newCell.x] = {
            ...updatedGridData[newCell.y][newCell.x],
            highlighted: true,
            selected: true
        }
        setCurrentCell(currentCell + diff)

        setCountCell(updatedGridData[newCell.y][newCell.x])
        setGridData(updatedGridData)
    }

    useEffect(() => {
        // Determine if currentRow was iterated upwards or downwards
        const direction = currentRow - prevCurrentRow.current;
        const {updatedCurrentCells, updatedGridData} = handleRowCount(direction, currentRow)
        const currentRowPattern = buildRowPattern(updatedCurrentCells)
        setCurrentRowPattern(currentRowPattern)
        setCurrentCell(0)
        handleCurrentCell(updatedCurrentCells, updatedGridData, 0, 0)


        // Update the previous value of currentRow
        prevCurrentRow.current = currentRow;

    }, [currentRow, rowDirection, gridHeight, gridWidth, accordion ]);

    useEffect(() => {
        prevCurrentRow.current = 0;
        if (locationState && locationState.rowDirection === rowDirection) {
            setCurrentRow(locationState.currentRow)
            setCurrentCell(locationState.currentCell)
        }
        else {
            setCurrentRow(0)
            setCurrentCell(0)
        }


        if (rowDirection === "VERTICAL") {
            setRowRange([0, (gridHeight - 1)])
        }
        else if (rowDirection === "HORIZONTAL") {
            setRowRange([0, (gridWidth - 1)])
        }
        else if (rowDirection === "DIAGONAL") {
            setRowRange([0, (gridHeight + gridWidth - 2)])
        }
        else if (rowDirection === "SPIRAL") {
            setRowRange([0, (Math.floor((Math.min(gridHeight, gridWidth) - 1) / 2))])
        }
    }, [rowDirection, gridHeight, gridWidth]);

    return (
        <React.Fragment>
            <Stack direction="column" style={{ minHeight: "100%", height: "100%" }}>
                <Divider label={isMobile ? "Row" : `Row (${rowRange[0] + 1} - ${rowRange[1] + 1})`} labelPosition="center"/>
                {
                    isMobile ?
                        <Center>
                    <ActionIcon.Group orientation={"vertical"}>
                        <ActionIcon
                            disabled={currentRow >= (rowRange[1])}
                            onClick={(e) => handleCurrentRow(currentRow, currentRow + 1)}
                            aria-label="Increase Row"
                        >
                            <IconCaretUpFilled />
                        </ActionIcon>

                        <ActionIcon
                            disabled={currentRow <= (rowRange[0])}
                            onClick={(e) => handleCurrentRow(currentRow, currentRow - 1)}
                            aria-label="Decrease Row"
                        >
                            <IconCaretDownFilled />
                        </ActionIcon>
                    </ActionIcon.Group>
                            </Center>
                        :
                    <NumberInput
                    value={currentRow + 1}
                    onChange={(e) => handleCurrentRow(currentRow, e - 1)}
                    allowNegative={false}
                    clampBehavior="strict"
                    min={rowRange[0] + 1}
                    max={rowRange[1] + 1}
                />
            }
                <Divider label={isMobile ? "Stitch" : `Stitch (${cellRange[0] + 1} - ${cellRange[1] + 1})`} labelPosition="center"/>
                {
                    isMobile ?
                        <Center>
                                            <ActionIcon.Group orientation={"vertical"}>
                        <ActionIcon
                            disabled={currentCell >= (cellRange[1])}
                            onClick={(e) => handleCurrentCell(currentCells, gridData, currentCell, currentCell + 1)}
                            aria-label="Increase Stitch"
                        >
                            <IconCaretUpFilled />
                        </ActionIcon>

                        <ActionIcon
                            disabled={currentCell <= (cellRange[0])}
                            onClick={(e) => handleCurrentCell(currentCells, gridData, currentCell,currentCell - 1)}
                            aria-label="Decrease Stitch"
                        >
                            <IconCaretDownFilled />
                        </ActionIcon>
                    </ActionIcon.Group>
                            </Center>
                        :
                        <NumberInput
                    value={currentCell + 1}
                    onChange={(e) => handleCurrentCell(currentCells, gridData, currentCell, e - 1)}
                    allowNegative={false}
                    clampBehavior="strict"
                    min={cellRange[0] + 1}
                    max={cellRange[1] + 1}
                />
                }

                {/*<Divider label='Row Pattern' labelPosition="center"/>*/}
                <ScrollArea h={"20vh"} style={{border: `3px solid ${getThemeColor('violet', theme)}`, flexGrow: 1}}>
                    <Stack>
                        <Space h="xs"/>

                        <Center>
                            <Badge color={"violet"}>{isMobile ? `${currentCells.length}` : `Total: ${currentCells.length} st.`}</Badge>
                        </Center>
                        <Center>

                            {countCell && <Badge color={countCell.color} style={{
                                color: isColorLight(countCell.color) ? "black" : "white",
                                border: "1px solid black"
                            }}>{isMobile ? "" : `Current: ${nearest(countCell.color).name}`}</Badge>}
                        </Center>
                        <Divider label="Row" labelPosition="center"/>

                        {currentRowPattern.map((block, blockIndex) => {
                            return <Center key={blockIndex}>
                                <Badge color={block.color} style={{
                                    color: isColorLight(block.color) ? "black" : "white",
                                    border: "1px solid black"
                                }} key={blockIndex}>{block.count} st.{isMobile ? "" : ` ${block.name}`}</Badge></Center>
                        })}
                        <Space h="xs"/>

                    </Stack>
                </ScrollArea>
                {/*<Button onClick={() => {handleOpenModal()}} justify="center">*/}
                {/*    Export*/}
                {/*</Button>*/}
                <Tooltip label="Export">
                    <ActionIcon
                        w={"100%"}
                        onClick={() => {handleOpenModal()}}
                    >
                        <IconWriting />
                    </ActionIcon>
                </Tooltip>
            </Stack>

            <FullPatternModal
                fullPatternModalOpened={fullPatternModalOpened}
                fullPatternModalClose={fullPatternModalClose}
                isMobile={isMobile}
                fullPatternText={fullPatternText}
                setFullPatternText={setFullPatternText}
                gridData={gridData}
                handleRowCount={handleRowCount}
                buildRowPattern={buildRowPattern}
                rowRange={rowRange}
            />

        </React.Fragment>
    )

}

export default CountCanvas;