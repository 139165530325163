import React from 'react';
import {AppShell, Text} from "@mantine/core";


const NotFound = () => {

    return (
        <React.Fragment>
            <AppShell.Main>
                <Text>Page Not Found</Text>
            </AppShell.Main>
        </React.Fragment>
    );
}

export default NotFound;
