// src/context/UserContext.js
import React, { createContext, useState, useEffect } from 'react';
import {makeAuthenticatedRequest} from "../Utils/authenticated_request";
import {useLocation} from "react-router-dom";

export const UserContext = createContext();

const authRequiredPages = [];


export const UserProvider = ({ children }) => {
    const location = useLocation();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);


  const getCurrentUser = async (redirect) => {
    try {
        const response = await makeAuthenticatedRequest(
            `${process.env.REACT_APP_API_HOST}/user/me/`,
            {redirect}
        );
        if (response.ok) {
            const jsonData = await response.json();
            setUser(jsonData)
        } else {
            console.error("Request failed with status:", response.status);
        }
    } catch (error) {
        console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }


  useEffect(() => {
      const basePath = location.pathname.split('/')[1];

      getCurrentUser(authRequiredPages.includes(basePath));
  }, [location.pathname]);

  const updateUser = (userData) => {
    setUser(userData);
  };

  return (
    <UserContext.Provider value={{ user, updateUser, loading, setLoading }}>
      {children}
    </UserContext.Provider>
  );
};
