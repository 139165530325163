import React from 'react';
import {Outlet} from "react-router-dom";


const AlphaPatternShell = () => {

    return (
        <Outlet/>
    );
}

export default AlphaPatternShell;
