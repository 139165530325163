import React, {useState} from 'react';
import {Box, Button, Grid, Stack, TextInput} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import {v4 as uuidv4} from "uuid";

const SymbolPatternCreate = () => {
    const navigate = useNavigate();

    const [name, setName] = useState('');

    const confirmCreate = () => {
        navigate('/symbolpattern/edit/', {state: {name: name, layers: [{uuid: uuidv4(), hidden: false, name: ""}], canvasSymbols: []}});
    }


    return (
    <React.Fragment>
      <Grid
        justify="center"
        align="center"
        columns={24}
        mih="100vh"
        h="100vh"
        mah="100vh"
      >
        <Grid.Col span={18} mih={"100vh"} h={"100vh"} mah={"100vh"}>
          <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <TextInput
                w={"100%"}
              label="Name"
              value={name}
              onChange={(event) => setName(event.currentTarget.value)}
              maxLength={64}
              style={{ marginBottom: 20 }}  // Add some space between the input and button
            />
            <Button
                fullWidth
              disabled={name === ""}
              onClick={() => { confirmCreate() }}
            >
              Confirm
            </Button>
          </Box>
        </Grid.Col>
      </Grid>
    </React.Fragment>
    );
}

export default SymbolPatternCreate;
