import React, {useState} from 'react';
import {Dropzone, MIME_TYPES} from "@mantine/dropzone";
import {IconCheck, IconPhoto, IconUpload, IconWindowMinimize, IconX} from "@tabler/icons-react";
import {
    ActionIcon,
    AppShell, Center,
    ColorInput,
    Divider,
    getThemeColor,
    Group,
    Image,
    NumberInput,
    Paper,
    rem,
    ScrollArea,
    SimpleGrid,
    Stack,
    Text,
    TextInput,
    Title,
    Tooltip,
    useMantineTheme
} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import {createCell} from "../../Utils/cell";
import {useMediaQuery} from "@mantine/hooks";

const AlphaPatternUpload = (
    {}
) => {
    const theme = useMantineTheme();
    const [files, setFiles] = useState([]);
    const [colorLimit, setColorLimit] = useState(5);
    const [error, setError] = useState(null);
    const [targetWidth, setTargetWidth] = useState(100);
    const [minimizedImageUrl, setMinimizedImageUrl] = useState(null);
    const [minimizedMatrix, setMinimizedMatrix] = useState([[]]);
    const [uniqueColors, setUniqueColors] = useState([]);
    const [name, setName] = useState('');

    const isMobile = useMediaQuery('(max-width: 768px)');


    const quantize = require('quantize');

    const navigate = useNavigate();

    const previews = files.map((file, index) => {
        const imageUrl = URL.createObjectURL(file);
        return <Image radius="md" key={index} src={imageUrl} onLoad={() => URL.revokeObjectURL(imageUrl)}/>;
    });

    const rgbToHex = (rgb) => {
        // Ensure that each RGB component is within the valid range (0 to 255)
        rgb = rgb.map(value => Math.min(255, Math.max(0, value)));

        // Convert each RGB component to a two-digit hexadecimal representation
        const hexColor = `#${rgb[0].toString(16).padStart(2, '0')}${rgb[1].toString(16).padStart(2, '0')}${rgb[2].toString(16).padStart(2, '0')}`;

        return hexColor;
    }

    const handleDrop = (acceptedFiles) => {
        setFiles(acceptedFiles);
        setUniqueColors([]);
    };

    const getImage = (file) => {
        const imageUrl = URL.createObjectURL(file);
        const img = new window.Image(); // Use standard HTML Image object
        img.src = imageUrl;
        return img;
    }

    const mapColors = (rgbData, topColors) => {
        // Maps RGB to nearest top color, and then converts it to hex
        const mappedData = rgbData.map((color) => (
            rgbToHex(findClosestColor(color, topColors))
        ))
        return mappedData
    }

    const calculateDistance = (color1, color2) => {
        // Calculate Euclidean distance between two colors in RGB space
        return Math.sqrt(
            Math.pow(color1[0] - color2[0], 2) +
            Math.pow(color1[1] - color2[1], 2) +
            Math.pow(color1[2] - color2[2], 2)
        );
    }

    const findClosestColor = (targetColor, colorList) => {
        // Can also use the quantize library for this, but I already implemented it like this
        let closestColor = colorList[0];
        let closestDistance = calculateDistance(targetColor, colorList[0]);

        for (let i = 1; i < colorList.length; i++) {
            const distance = calculateDistance(targetColor, colorList[i]);

            if (distance < closestDistance) {
                closestColor = colorList[i];
                closestDistance = distance;
            }
        }

        return closestColor;
    }

    const getRgbData = (ctx, startX, startY, widthX, heightY) => {
        const rgbData = []
        const imageData = ctx.getImageData(startX, startY, widthX, heightY)
        for (let i = 0; i < imageData.data.length; i += 4) {
            const red = imageData.data[i];
            const green = imageData.data[i + 1];
            const blue = imageData.data[i + 2];
            rgbData.push([red, green, blue])
        }
        return rgbData
    }

    const createMatrix = (imageData, width, height) => {
        if (imageData.length !== width * height) {
            throw new Error('Array length does not match specified width and height.');
        }
        const matrix = [];
        for (let i = 0; i < height; i++) {
            const row = imageData.slice(i * width, (i + 1) * width);
            matrix.push(row);
        }
        return matrix;
    }

    const resizeMatrix = (matrix, targetWidth) => {
        // Get the current dimensions of the matrix
        const originalHeight = matrix.length;
        const originalWidth = matrix[0].length;

        // Calculate the scaling factor for width
        const scaleFactor = targetWidth / originalWidth;

        // Calculate the new height based on the aspect ratio
        const targetHeight = Math.round(originalHeight * scaleFactor);

        // Create a new matrix with the target dimensions
        const resizedMatrix = new Array(targetHeight);

        for (let i = 0; i < targetHeight; i++) {
            resizedMatrix[i] = new Array(targetWidth);
        }

        // Perform interpolation to fill in the new matrix
        for (let i = 0; i < targetHeight; i++) {
            for (let j = 0; j < targetWidth; j++) {
                // Calculate the corresponding position in the original matrix
                const originalRow = Math.floor(i / scaleFactor);
                const originalCol = Math.floor(j / scaleFactor);

                // Assign the value from the original matrix to the resized matrix
                resizedMatrix[i][j] = matrix[originalRow][originalCol];
            }
        }

        return resizedMatrix;
    }

    function drawMatrixOnCanvas(matrix, canvasWidth, canvasHeight) {
        const minimizedCanvas = document.createElement('canvas');
        const minimizedCtx = minimizedCanvas.getContext('2d');
        const matrixWidth = matrix[0].length;
        const matrixHeight = matrix.length
        minimizedCanvas.width = canvasWidth
        minimizedCanvas.height = canvasHeight

        // Calculate the scaling factors for width and height
        const scaleX = minimizedCanvas.width / matrixWidth;
        const scaleY = minimizedCanvas.height / matrixHeight;

        // Iterate through the matrix and draw on the canvas
        for (let i = 0; i < matrixHeight; i++) {
            for (let j = 0; j < matrixWidth; j++) {
                // Calculate the position and dimensions on the canvas
                const x = j * scaleX;
                const y = i * scaleY;
                const width = scaleX;
                const height = scaleY;

                minimizedCtx.fillStyle = matrix[i][j];

                // Draw a rectangle on the canvas
                minimizedCtx.fillRect(x, y, width, height);
            }
        }
        const dataUrl = minimizedCanvas.toDataURL(); // Convert canvas to data URL
        setMinimizedImageUrl(dataUrl); // Update state with the URL of the minimized image
    }

    const handleGenerate = () => {
            readPixels()
    }

    const readPixels = () => {
        const img = getImage(files[0])
        img.onload = () => {
            const originalCanvas = document.createElement('canvas');
            const originalCtx = originalCanvas.getContext('2d');
            originalCanvas.width = img.width
            originalCanvas.height = img.height;

            originalCtx.drawImage(img, 0, 0, img.width, img.height);
            const rgbData = getRgbData(originalCtx, 0, 0, img.width, img.height)
            try {
                const topColors = quantize(rgbData, colorLimit).palette();
                setUniqueColors(mapColors(topColors, topColors).map((color) => {
                    return {
                        original: color,
                        new: color,
                    }
                }))
                const mappedData = mapColors(rgbData, topColors)
                const matrix = createMatrix(mappedData, img.width, img.height)
                const minimizedMatrix = resizeMatrix(matrix, targetWidth)
                drawMatrixOnCanvas(minimizedMatrix, matrix[0].length, matrix.length)
                setError(null);
                setMinimizedMatrix(minimizedMatrix)

            } catch (error) {
                setError('Error: Color limit must be between 2 and the total colors within the image.');
            }
        };

        img.onerror = () => {
            console.error('Error loading image');
        };
    };

    const confirmCreate = () => {
        const gridData = minimizedMatrix.map((row, y) => {
                        return row.map((color, x) => {
                            return createCell(x, y, color)
                        })
                    })
        navigate('/alphapattern/edit/',
            {
                state: {
                    imageGridData: gridData,
                    name: name,
                    rowDirection: "VERTICAL",
                    rowStarting: "TOP_LEFT",
                    rowClockwise: true,
                    rowSwitching: false,
                    currentRow: 0,
                    currentCell: 0,
        }});
    }

    const handleColorChange = (color, index) => {
        setUniqueColors((prevData) => {
            const newData = [...prevData];
            newData[index] = {...newData[index], new: color};
            return newData;
        });
    };

    return (
        <React.Fragment>
            {files[0] ?
                <>
            <AppShell.Main>
                    {/*<Stack gap={0} direction="column" style={{minHeight: "80vh", height: "80vh"}}>*/}
                        <ScrollArea
                                    mt="5vh" mih="80vh" h="80vh">
                            <SimpleGrid
                                cols={isMobile ? 1 : 2}
                                w="100%"
                                mih="80vh"
                            >
                                <Paper shadow="xs" p="xs" style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    {previews}
                                    {!files[0] && <Title>Uploaded Image</Title>}
                                </Paper>
                                <Paper shadow="xs" p="xs" style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    {minimizedImageUrl &&
                                        <Image radius="md" src={minimizedImageUrl} alt="Minimized Image"/>}
                                    {!minimizedImageUrl && <Title>Pattern Preview</Title>}
                                </Paper>
                                                        </SimpleGrid>
                        </ScrollArea>
                    {/*</Stack>*/}
            </AppShell.Main>
            <AppShell.Aside p={isMobile ? 0 : "md"}>
                <ScrollArea h={"100%"}>

                    <Stack>
                                <NumberInput
                                    label="Colors"
                                    description={isMobile ? "" : "The amount of unique colors that will be in the image"}
                                    value={colorLimit}
                                    onChange={setColorLimit}
                                    onChange={(value) => {
                                        setColorLimit(value);
                                        setError(null); // Reset the error state when the user changes the color limit
                                    }}
                                    error={error}
                                />
                                <NumberInput
                                    label="Width"
                                    description={isMobile ? "" : "The width (in stitches) of the image"}
                                    value={targetWidth}
                                    onChange={setTargetWidth}
                                    min={1}
                                    max={1000}
                                    allowNegative={false}
                                    clampBehavior="strict"
                                />
                                <Divider/>
                                <ScrollArea h={"20vh"} style={{border: `3px solid ${getThemeColor('violet', theme)}`}}>
                                    {uniqueColors.map((color, index) => {
                                        return <ColorInput
                                            key={index}
                                            value={color.new}
                                            onChange={(e) => handleColorChange(e, index)}
                                            format="hex"
                                            swatches={[
                                                "#25262b",
                                                "#868e96",
                                                "#fa5252",
                                                "#e64980",
                                                "#be4bdb",
                                                "#7950f2",
                                                "#4c6ef5",
                                                "#228be6",
                                                "#15aabf",
                                                "#12b886",
                                                "#40c057",
                                                "#82c91e",
                                                "#fab005",
                                                "#fd7e14",
                                            ]}
                                        />
                                    })}
                                </ScrollArea>
                                <Divider/>
                                <Tooltip label="Generate">
                                <ActionIcon
                                    w={"100%"}
                                    disabled={!files[0]}
                                    onClick={() => {
                                        handleGenerate()
                                    }}
                                    variant="gradient"
                                    size="xl"
                                    aria-label="Generate Alpha Pattern"
                                >
                                    <IconWindowMinimize />
                                </ActionIcon>
                                </Tooltip>
                        <TextInput
                            label="Name"
                            value={name}
                            onChange={(event) => setName(event.currentTarget.value)}
                            maxLength={64}
                        />
                        <Tooltip label="Confirm">
                        <ActionIcon
                            w={"100%"}
                            disabled={!minimizedImageUrl || name === ""}
                            onClick={() => {
                                confirmCreate()
                            }}
                            variant="gradient"
                            size="xl"
                            aria-label="Confirm Creation"
                        >
                            <IconCheck />
                        </ActionIcon>
                        </Tooltip>
                    </Stack>
                </ScrollArea>
            </AppShell.Aside>
            </> :
                <>
                <Dropzone
                            style={{marginTop: "10vh", maxWidth: "100%", height: "85vh", minHeight: "85vh"}}
                            className={"dropzone"}
                            onDrop={handleDrop}
                            onReject={(files) => console.log('rejected files', files)}
                            maxSize={3 * 1024 ** 2}
                            accept={[
                                MIME_TYPES.png,
                                MIME_TYPES.jpeg,
                                MIME_TYPES.webp
                            ]}
                            // loading
                            // disabled
                        >
                            <Stack
                                   gap="xl"
                                // mih={220}
                                   style={{pointerEvents: 'none'}}>
                                <Dropzone.Accept>
                                    <IconUpload
                                        style={{width: rem(52), height: rem(52), color: 'var(--mantine-color-white)'}}
                                        stroke={1.5}
                                    />
                                </Dropzone.Accept>
                                <Dropzone.Reject>
                                    <IconX
                                        style={{width: rem(52), height: rem(52), color: 'var(--mantine-color-white)'}}
                                        stroke={1.5}
                                    />
                                </Dropzone.Reject>
                                <Dropzone.Idle>
                                    <Center>
                                    <IconPhoto
                                        style={{width: rem(100), height: rem(100), color: 'var(--mantine-color-white)'}}
                                        stroke={1.5}
                                    />
                                    </Center>
                                </Dropzone.Idle>

                                    <Center>
                                    <Title order={1} inline>
                                        Drag images here or click to select files
                                    </Title>
                                        </Center>
                                    <Center>
                                    <Title order={2} c="dimmed" inline mt={7}>
                                        The file should not exceed 5mb
                                    </Title>
                                </Center>
                            </Stack>
                        </Dropzone>
                </>
            }
        </React.Fragment>
    );
};

export default AlphaPatternUpload;
