import React from "react";
import {ActionIcon, Center, Flex, Stack, Text} from "@mantine/core";
import Footer from "../Footer";
import {IconSquare, IconUpload} from "@tabler/icons-react";
import {Link as RouterLink} from "react-router-dom";


const AlphaPatternCreate = () => {
    return (
        <React.Fragment>
            <Flex
                pt="15vh"
                style={{minHeight: "85vh", height: "85vh"}}
                justify="space-around"
                align="stretch"
            >
                <ActionIcon
                    variant="gradient"
                    w="40%"
                    h="100%"
                    aria-label="Gradient action icon"
                    component={RouterLink}
                    to="blank"
                >
                    <Stack>
                        <Center>
                            <IconSquare
                                style={{ width: "10rem", height: "10rem" }}
                            />
                        </Center>
                        <Text>Start with a blank canvas</Text>
                    </Stack>
                </ActionIcon>
                <ActionIcon
                    variant="gradient"
                    w="40%"
                    h="100%"
                    aria-label="Gradient action icon"
                    component={RouterLink}
                    to="upload"
                >
                    <Stack>
                        <Center>
                            <IconUpload
                                style={{ width: "10rem", height: "10rem" }}
                            />
                        </Center>
                        <Text>Upload an existing image</Text>
                    </Stack>
                </ActionIcon>
            </Flex>

            <Footer/>
        </React.Fragment>
    );
};

export default AlphaPatternCreate;
