import {Center, rem, SegmentedControl, Stack, Tooltip} from "@mantine/core";
import {IconClick, IconFileExport, IconNeedleThread, IconSquaresFilled} from "@tabler/icons-react";
import React from "react";

const ToolSelect = (
    {
        accordion,
        setAccordion,
    }) => {

    return (
            <Stack>
                <Center>
                        <SegmentedControl
                            value={accordion}
                            onChange={setAccordion}
                            color="violet"
                            orientation="vertical"
                            data={[
                                {
                                    label: (
                                        <Center>
                                            <Tooltip label="Select">
                                                <IconClick style={{width: rem(16), height: rem(16)}}/>
                                            </Tooltip>
                                            {/*<Box ml={10}>Vertical</Box>*/}
                                        </Center>
                                    ),
                                    value: 'select'
                                },
                                {
                                    label: (
                                        <Center>
                                            <Tooltip label="Symbols">
                                                <IconNeedleThread style={{width: rem(16), height: rem(16)}}/>
                                            </Tooltip>
                                            {/*<Box ml={10}>Diagonal</Box>*/}
                                        </Center>
                                    ),
                                    value: 'symbols'
                                },
                                {
                                    label: (
                                        <Center>
                                            <Tooltip label="Layers">
                                                <IconSquaresFilled style={{width: rem(16), height: rem(16)}}/>
                                            </Tooltip>
                                            {/*<Box ml={10}>Diagonal</Box>*/}
                                        </Center>
                                    ),
                                    value: 'layers'
                                },
                                {
                                    label: (
                                        <Center>
                                            <Tooltip label="Export">
                                                <IconFileExport style={{width: rem(16), height: rem(16)}}/>
                                            </Tooltip>
                                            {/*<Box ml={10}>Diagonal</Box>*/}
                                        </Center>
                                    ),
                                    value: 'export'
                                },
                            ]}
                        />
                    </Center>
                </Stack>
    )

}

export default ToolSelect;