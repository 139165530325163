import {
    Card, Center,
    Divider,
    getThemeColor,
    NumberInput,
    rem,
    ScrollArea,
    Stack,
    Text,
    TextInput,
    useMantineTheme
} from "@mantine/core";
import React, {useState} from "react";
import { Image as MantineImage } from '@mantine/core';
import {IconSearch, IconX} from "@tabler/icons-react";

const SymbolsCanvas = (
    {
        accordion,
        symbolSize,
        setSymbolSize,
        selectedSymbol,
        setSelectedSymbol,
        symbols,
    }) => {
    const theme = useMantineTheme();

    const [symbolSearch, setSymbolSearch] = useState('');
    const [displayedSymbols, setDisplayedSymbols] = useState(symbols);

    const handleSymbolSelect = (symbol) => {
        setSelectedSymbol(symbol)
    }

    const handleDeleteSearch = () => {
        setSymbolSearch("")
        setDisplayedSymbols(symbols)
    }

    const handleSymbolSearch = (event) => {
        setSymbolSearch(event.currentTarget.value)


        const filteredSymbols = symbols.filter((symbol) =>
            symbol.name.toLowerCase().includes(event.currentTarget.value.toLowerCase())
        );

        if (filteredSymbols !== displayedSymbols) {
            setDisplayedSymbols(filteredSymbols);
        }
    };

    return (
        <React.Fragment>
            {accordion === "symbols" &&
                <Stack direction="column" style={{ minHeight: "100%", height: "100%" }}>
                    <Divider label="Size (1 - 500)" labelPosition="center"/>
                    <NumberInput
                        value={symbolSize}
                        onChange={(e) => setSymbolSize(e)}
                        allowNegative={false}
                        clampBehavior="strict"
                        min={1}
                        max={500}
                    />
                    <Divider label="Selected" labelPosition="center"/>
                    <Card
                        shadow="sm"
                        radius="md"
                        withBorder
                        style={{ overflow: "visible" }}
                    >
                        <Card.Section>
                            <Center>
                            <MantineImage
                                h={"5vh"}
                                mt="xs"
                                src={selectedSymbol.file}
                                alt={selectedSymbol.name}
                                style={{objectFit: 'contain', maxHeight: '100%', maxWidth: '100%'}}
                            />
                                </Center>
                        </Card.Section>
                        <Text ta="center" tt="capitalize" size="xs" mt="xs">{selectedSymbol.name}</Text>
                    </Card>
                    <Divider label="Search" labelPosition="center"/>

                    <TextInput
                        leftSection={<IconSearch style={{width: rem(16), height: rem(16)}}/>}
                        leftSectionPointerEvents="none"
                        rightSection={symbolSearch ? <IconX onClick={handleDeleteSearch} style={{
                            width: rem(16),
                            height: rem(16)
                        }}/> : null}
                        aria-label="Search Symbols"
                        value={symbolSearch}
                        onChange={(event) => handleSymbolSearch(event)}
                    />
                    <ScrollArea
                                style={{
                                    border: `3px solid ${getThemeColor('violet', theme)}`,
                                    flexGrow: 1,
                    }}>

                        {displayedSymbols.map((symbol) => {
                            const backgroundColor = symbol === selectedSymbol ? `${getThemeColor('violet', theme)}` : "#ffffff"
                            return (
                                <Card
                                    style={{backgroundColor: backgroundColor}}
                                    key={symbol.name}
                                    shadow="sm"
                                    mt="xs"
                                    radius="md"
                                    withBorder
                                    onClick={() => handleSymbolSelect(symbol)}
                                >
                                    <Card.Section>
                                        <Center>
                                        <MantineImage
                                            h={"5vh"}
                                            mt="xs"
                                            src={symbol.file}
                                            alt={symbol.name}
                                            style={{
                                                objectFit: 'contain',
                                                maxHeight: '100%',
                                                maxWidth: '100%'
                                            }}
                                        />
                                        </Center>
                                    </Card.Section>
                                    <Text ta="center" tt="capitalize" size="xs"
                                          c={symbol === selectedSymbol ? "white" : "black"}
                                          mt="xs">{symbol.name}</Text>
                                </Card>
                            )
                        })}
                    </ScrollArea>
                </Stack>
            }
        </React.Fragment>
    )

}

export default SymbolsCanvas;