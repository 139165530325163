import React from 'react';
import {AppShell} from "@mantine/core";


const TutorialsShell = () => {

    return (
        <React.Fragment>
            <AppShell.Main>
                Tutorials
            </AppShell.Main>
        </React.Fragment>
    );
}

export default TutorialsShell;
