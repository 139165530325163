import {Parallax} from "react-scroll-parallax";

const LargeYarn = () => {
  return (
              <div
      style={{
          width: "100%",
          position: "absolute",
          top: "10vh",
          zIndex: -1
      }}
    >
    <Parallax speed={-200}>
        <svg
   width="100%"
   height="100%"
   viewBox="0 0 106.3574 37.797695"
   version="1.1"
   id="svg1"
   xmlns="http://www.w3.org/2000/svg"
        >
  <defs
     id="defs1" />
  <g
     id="layer1"
     transform="translate(-21.8,-189.65806)">
    <path
       fill="#000000"
       fillOpacity="0"
       stroke="#845ef7"
       strokeWidth="4"
       strokeLinecap="butt"
       strokeLinejoin="round"
       strokeDasharray="none"
       strokeDashoffset="32"
       d="m 25,204.99999 c 4.999997,-6.66666 10,-13.33333 15.00005,-10.83322 5.000051,2.50012 9.999949,14.16655 18.3334,13.33309 8.33345,-0.83347 19.999881,-14.16653 31.666666,-13.33305 C 101.6669,195.00029 113.33334,209.99999 125,224.99999"
       id="path6" />
  </g>
</svg>
    </Parallax>
              </div>
  );
};

export default LargeYarn