import {Parallax} from "react-scroll-parallax";

const MediumYarn = () => {
  return (
              <div
      style={{
          width: "100%",
          position: "absolute",
          top: "10vh",
          zIndex: -1
      }}
    >
    <Parallax speed={-100}>
      <svg
   width="100%"
   height="100%"
   viewBox="0 0 96.128136 70.280975"
   version="1.1"
   id="svg1"
   xmlns="http://www.w3.org/2000/svg"
      >
  <defs
     id="defs1" />
  <g
     id="layer1"
     transform="translate(-28.584723,-102.79965)">
    <path
       fill="#000000"
       fillOpacity="0"
       stroke="#7048e8"
       strokeWidth="2"
       strokeLinecap="butt"
       strokeLinejoin="round"
       strokeDasharray="24, 2"
       strokeDashoffset="16"
       d="m 30,99.999997 c 1.666664,1.666663 3.333332,3.333333 5.833367,4.166673 2.500035,0.83333 5.8333,0.83333 8.333316,1.66668 2.500017,0.83335 4.166649,2.49998 6.666683,4.16667 2.500035,1.66668 5.833299,3.33331 9.166667,3.3333 3.333369,-2e-5 6.666637,-1.66665 8.333303,-3.33334 1.666666,-1.66668 1.666666,-3.33332 2.500018,-5 0.833351,-1.66668 2.499982,-3.33332 4.166662,-3.3333 1.66668,1e-5 3.333316,1.66665 5,4.16669 1.666684,2.50003 3.333316,5.83329 5.833351,6.66661 2.500035,0.83332 5.833303,-0.83331 8.333319,-5.8334 2.500015,-5.00008 4.166648,-13.333249 6.666684,-16.666567 2.50003,-3.333317 5.8333,-1.666682 8.33331,0.833351 2.50002,2.500033 4.16665,5.833302 6.66669,9.166666 2.50003,3.33337 5.8333,6.66663 6.66661,10 0.83332,3.33337 -0.83331,6.66663 -4.1667,7.49995 -3.33338,0.83331 -8.33328,-0.83332 -11.66663,-3.33335 -3.33335,-2.50003 -4.99998,-5.8333 -7.500017,-4.16656 -2.500034,1.66673 -5.833301,8.33326 -9.166668,13.33329 -3.333367,5.00004 -6.666629,8.3333 -10.833346,9.16662 C 74.999902,133.3333 70,131.66667 67.5,129.16663 c -2.499999,-2.50004 -2.499999,-5.8333 -0.8333,-7.49997 1.6667,-1.66667 4.999968,-1.66667 6.666634,3e-5 C 75,123.33339 75,126.66666 71.6666,130.00003 c -3.333401,3.33337 -9.999933,6.66663 -15.833317,9.16665 -5.833384,2.50001 -10.833286,4.16664 -14.999984,6.66668 -4.166698,2.50003 -7.499968,5.8333 -10.833299,9.16663"
       id="path1"
       transform="translate(3.7087485e-5,16.666633)" />
  </g>
</svg>
    </Parallax>
              </div>
  );
};

export default MediumYarn