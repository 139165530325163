import {
  Accordion,
  ActionIcon, Button,
  Center,
  getThemeColor,
  Group,
  Paper, Popover,
  Radio,
  rem,
  ScrollArea,
  Stack,
  Switch, TextInput,
  useMantineTheme
} from "@mantine/core";
import {
  IconCaretDown,
  IconCheck,
  IconEye,
  IconEyeOff,
  IconPencil,
  IconPlus,
  IconSquaresFilled,
  IconTrash,
  IconX
} from "@tabler/icons-react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const LayerCanvas = ({
  layers,
  setLayers,
  selectedLayer,
  setSelectedLayer,
  accordion,
}) => {
  const theme = useMantineTheme();
  const [scrollAreaWidth, setScrollAreaWidth] = useState("100%");
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [editingLayerUuid, setEditingLayerUuid] = useState(null)
  const [editingLayerName, setEditingLayerName] = useState("");



  useEffect(() => {
    const updateScrollAreaWidth = () => {
      if (scrollAreaRef.current) {
        setScrollAreaWidth("100%");
      }
    };

    window.addEventListener('resize', updateScrollAreaWidth);

    return () => {
      window.removeEventListener('resize', updateScrollAreaWidth);
    };
  }, []);

  const scrollAreaRef = useRef(null);

  const addLayer = () => {
    const newLayer = { uuid: uuidv4(), hidden: false, name: "" };
    setLayers((prevLayers) => [...prevLayers, newLayer]);
  };

  const toggleHideLayer = (uuid) => {
    setLayers((prevLayers) =>
      prevLayers.map((layer) =>
        layer.uuid === uuid
          ? { ...layer, hidden: !layer.hidden }
          : layer
      )
    );
  };

  const deleteLayer = (uuid) => {
    setLayers((prevLayers) => {
      const updatedLayers = prevLayers.filter(
        (layer) => layer.uuid !== uuid
      );

      if (selectedLayer.uuid === uuid) {
        setSelectedLayer(
          updatedLayers.length > 0 ? updatedLayers[0] : null
        );
      }

      return updatedLayers;
    });
  };

  const editLayer = (layer) => {
    setEditingLayerUuid(layer.uuid)
    setEditingLayerName(layer.name)
  }

  const clearEditLayer = () => {
    setEditingLayerUuid(null);
    setEditingLayerName("");
  }

const confirmEditLayer = (uuid) => {
  setLayers((prevLayers) =>
    prevLayers.map((layer) =>
      layer.uuid === uuid ? { ...layer, name: editingLayerName } : layer
    )
  );
  clearEditLayer()
};



  const handleDragEnd = (result) => {
  if (!result.destination) {
    return; // dropped outside the list
  }
  const newLayers = Array.from(layers);
  const [removed] = newLayers.splice(result.source.index, 1);
  newLayers.splice(result.destination.index, 0, removed);

  setLayers(newLayers);
  setDraggedIndex(null);
};

  return (
      <React.Fragment>
      {accordion === 'layers' &&
            <React.Fragment>
            <Stack direction="column" style={{ width: "100%", minHeight: "100%", height: "100%" }}>
            <DragDropContext onDragEnd={handleDragEnd} style={{ flexGrow: 1 }}>
              <Droppable droppableId="layers" style={{ height: "100%" }}>
                {(provided) => (
                  <ScrollArea
                    w={scrollAreaWidth}
                    h={"100%"}
                    ref={(el) => {
                      scrollAreaRef.current = el;
                      provided.innerRef(el);
                    }}
                    {...provided.droppableProps}
                    style={{
                      border: `3px solid ${getThemeColor("violet", theme)}`,
                    }}
                  >
                    <Stack>
                      {layers.map((layer, layerIndex) => (
                        <Draggable
                          key={layer.uuid}
                          draggableId={layer.uuid}
                          index={layerIndex}
                          style={{ width: '100%' }}
                        >
                          {(provided) => (
                            <Paper
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              p="xs"
                              w={`${scrollAreaWidth}px`}
                              shadow="sm"
                              radius="md"
                              withBorder
                              key={layer.uuid}
                            >
                              <Group>
                                {
                                  layer.uuid === editingLayerUuid ?
                                          <TextInput
      value={editingLayerName}
      onChange={(event) => setEditingLayerName(event.currentTarget.value)}
    />
                                              :
                                  (layer.name ? layer.name : `Layer ${layerIndex + 1}`)
                                }
                                                                        <Radio
                                                                            size={"xs"}
                                  checked={layer.uuid === selectedLayer.uuid}
                                  onChange={() => setSelectedLayer(layer)}
                                />
                                <Switch
                                  size="xs"
                                  onLabel={<IconEye  size={"xs"}/>}
                                  offLabel={<IconEyeOff size={"xs"}/>}
                                  checked={!layer.hidden}
                                  onChange={() => toggleHideLayer(layer.uuid)}
                                />

                                  <Popover position="bottom" clickOutsideEvents={['mouseup', 'touchend']}>
      <Popover.Target>
                                          <IconCaretDown
                                              size={"xs"}
                                    style={{ width: rem(20) }}
                                    stroke={1.5}
                                  />
      </Popover.Target>
      <Popover.Dropdown>
                                        {editingLayerUuid !== layer.uuid &&
                                    <React.Fragment>

                                <ActionIcon
                                  size="sm"
                                  aria-label="Edit-Layer"
                                  onClick={() => editLayer(layer)}
                                >
                                  <IconPencil
                                    style={{ width: rem(20) }}
                                    stroke={1.5}
                                  />
                                </ActionIcon>
                                        <ActionIcon
                                  size="sm"
                                  aria-label="Delete-Layer"
                                  disabled={layers.length <= 1}
                                  onClick={() => deleteLayer(layer.uuid)}
                                >
                                  <IconTrash
                                    style={{ width: rem(20) }}
                                    stroke={1.5}
                                  />
                                </ActionIcon>
                                    </React.Fragment>
        }
                                {editingLayerUuid === layer.uuid &&
                                    <React.Fragment>
                                                                <ActionIcon
                                  size="sm"
                                  aria-label="Cancel-Edit-Layer-Name"
                                  // c={getThemeColor("red", theme)}
                                  onClick={() => clearEditLayer()}
                                >
                                  <IconX
                                    style={{ width: rem(20) }}
                                    stroke={1.5}
                                  />
                                </ActionIcon>
                                <ActionIcon
                                  size="sm"
                                  aria-label="Confirm-Edit-Layer-Name"
                                  onClick={() => confirmEditLayer(layer.uuid)}
                                >
                                  <IconCheck
                                    style={{ width: rem(20) }}
                                    stroke={1.5}
                                  />
                                </ActionIcon>
                                      </React.Fragment>
                                }
      </Popover.Dropdown>
    </Popover>
                                </Group>
                            </Paper>
                          )}
                        </Draggable>
                      ))}
                    </Stack>
                  </ScrollArea>
                )}
              </Droppable>
            </DragDropContext>
            <Center style={{ paddingBottom: "10px" }}>
              <ActionIcon
                variant="gradient"
                aria-label="Add-Layer"
                disabled={layers.length >= 50}
                onClick={addLayer}
              >
                <IconPlus style={{ width: rem(20) }} stroke={1.5} />
              </ActionIcon>
            </Center>
          </Stack>
      </React.Fragment>
      }
  </React.Fragment>
  );
};

export default LayerCanvas;
