import React, {useContext, useEffect, useRef, useState} from "react";
import {makeAuthenticatedRequest} from "../../Utils/authenticated_request";
import {
    ActionIcon, Alert,
    Badge,
    Button,
    Card,
    Center,
    Grid,
    Group,
    rem,
    ScrollArea,
    Stack,
    Text,
    TextInput,
    Title,
    Tooltip,
    useMantineTheme
} from '@mantine/core';
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {IconInfoCircle, IconLock, IconPlus, IconSearch, IconX} from "@tabler/icons-react";
import {UserContext} from "../../Context/User";
import Footer from "../Footer";


const RowCounterManager = (
    {}) => {
    const navigate = useNavigate();
    const theme = useMantineTheme();
    const {user} = useContext(UserContext);
    const canvasRef = useRef(null);

    const [rowCounters, setRowCounters] = React.useState([])
    const [rowCounterSearch, setRowCounterSearch] = useState('');
    const [displayedRowCounters, setDisplayedRowCounters] = useState(rowCounters);

    const isButtonDisabled = user?.tier !== "PREMIUM" && rowCounters.length > 3;

    const fetchRowCounters = async () => {
        try {
            const response = await makeAuthenticatedRequest(
                `${process.env.REACT_APP_API_HOST}/row_counter/`,
                {redirect: false}
            );

            if (response.ok) {
                const jsonData = await response.json();
                setRowCounters(jsonData.results)
            setDisplayedRowCounters(jsonData.results)
        } else {
            console.error("Request failed with status:", response.status);
        }
    } catch (error) {
        console.error("Error fetching data:", error);
    }
    }

    const deleteRowCounter = async (rowCounter) => {
        try {
            const response = await makeAuthenticatedRequest(
                `${process.env.REACT_APP_API_HOST}/row_counter/${rowCounter.uuid}/`,
                {method: 'DELETE'},
            );

            if (response.ok) {
                await fetchRowCounters()
            } else {
                console.error("Request failed with status:", response.status);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const editRowCounter = (rowCounter, rowCounterIndex) => {
        if (user?.tier !== "PREMIUM" && rowCounterIndex >= 3) {
            return
        }
        navigate('/rowcounter/edit/', {
            state: {
                stitch: rowCounter.stitch,
                rows: rowCounter.rows,
                name: rowCounter.name,
                uuid: rowCounter.uuid
            }
        });
    }

    const handleDeleteSearch = () => {
        setRowCounterSearch("")
        setDisplayedRowCounters(rowCounters)
    }

    const handleRowCounterSearch = (event) => {
        setRowCounterSearch(event.currentTarget.value)


        const filteredRowCounters = rowCounters.filter((rowCounter) =>
            rowCounter.name.toLowerCase().includes(event.currentTarget.value.toLowerCase())
        );

        if (filteredRowCounters !== displayedRowCounters) {
            setDisplayedRowCounters(filteredRowCounters);
        }
    };

    useEffect(() => {
        fetchRowCounters()
    }, []);

    return (
        <React.Fragment>
            <canvas ref={canvasRef} style={{display: 'none'}}/>
            <Grid grow justify="center" align="center" columns={24}>
                <Grid.Col span={3}>

                </Grid.Col>
                <Grid.Col span={18}>
                    <Stack pt="15vh" direction="column" style={{minHeight: "85vh", height: "85vh"}}>
                        {isButtonDisabled ? (<Tooltip
                            label={"Max 3 allowed. Either re-activate Premium or delete some items."}
                        >
                            <Button p={20} disabled fullWidth size="xl" leftSection={<IconPlus size={20}/>}
                                    component={RouterLink}
                                    to="create">Create New</Button>
                        </Tooltip>) : (<Button p={20} disabled={rowCounters.length >= 100} fullWidth size="xl"
                                               leftSection={<IconPlus size={20}/>}
                                               component={RouterLink}
                                               to="create">Create New</Button>)}
                        <TextInput
                            leftSection={<IconSearch style={{width: rem(16), height: rem(16)}}/>}
                            leftSectionPointerEvents="none"
                            rightSection={rowCounterSearch ? <IconX onClick={handleDeleteSearch} style={{
                                width: rem(16),
                                height: rem(16)
                            }}/> : null}
                            aria-label="Search Row Counters"
                            value={rowCounterSearch}
                            onChange={(event) => handleRowCounterSearch(event)}
                        />
                        <ScrollArea
                                    style={{flexGrow: 1}}>

                            <Grid justify="flex-start" align="start">
                                {!user &&
                                    <Alert title="Not Signed In" icon={<IconInfoCircle />}>
                                      You're not signed in, so you won't be able to save any counter you create.
                                    </Alert>
                                }
                                {displayedRowCounters.map((rowCounter, rowCounterIndex) => {
                                    return (
                                        <Grid.Col key={rowCounter.uuid} span={{xl: 3, lg: 4, md: 6, sm: 12}}>
                                            <Card shadow="sm" padding="xs" radius="md" withBorder
                                                  onClick={() => editRowCounter(rowCounter, rowCounterIndex)}>
                                                <Group justify="space-between" mt="md" mb="xs">
                                                    <div></div>
                                                    <Title fw={500} order={4}>
                                                        {(user?.tier !== "PREMIUM" && rowCounterIndex >= 3) &&
                                                            <Tooltip
                                                                label={"Max 3 allowed. Either re-activate Premium or delete some items."}><IconLock/></Tooltip>}
                                                        {rowCounter.name}
                                                    </Title>
                                                    <Tooltip label="Delete Row Counter">
                                                        <ActionIcon
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                deleteRowCounter(rowCounter)
                                                            }}
                                                        >
                                                            <IconX size={20}/>
                                                        </ActionIcon>
                                                    </Tooltip>
                                                </Group>
                                                <Card.Section p={"md"}>
                                                    <Center>
                                                    <Text>Stitch <Badge>{rowCounter.stitch + 1}</Badge></Text>
                                                        </Center>
                                                    <Center>
                                                    <Text>Row <Badge>{rowCounter.rows.length}</Badge></Text>
                                                        </Center>
                                                </Card.Section>
                                            </Card>
                                        </Grid.Col>
                                    )
                                })}
                            </Grid>
                        </ScrollArea>
                    </Stack>
                </Grid.Col>
                <Grid.Col span={3}>

                </Grid.Col>
            </Grid>
            <Footer/>
        </React.Fragment>
    )

}

export default RowCounterManager;