import './App.css';
import Shell from "./components/Shell";
import Theme from "./theme";
import {MantineProvider} from "@mantine/core";
import {useContext} from "react";
import {UserContext} from "./Context/User";
import '@mantine/notifications/styles.css';
import {Notifications} from "@mantine/notifications";



function App() {
  const { user } = useContext(UserContext);

  return (
      <MantineProvider theme={Theme} classNamesPrefix="crochet" defaultColorScheme={user?.color_scheme || "light"}>
          <Notifications />
          <div className="App">
              <Shell/>
          </div>
      </MantineProvider>
  );
}

export default App;
