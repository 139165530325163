import {
    ActionIcon,
    Center,
    ColorInput,
    Divider,
    rem,
    ScrollArea,
    SegmentedControl,
    Stack,
    Tooltip
} from "@mantine/core";
import {IconArrowBackUp, IconBrush, IconBucketDroplet, IconEraser, IconRoute2, IconX} from "@tabler/icons-react";
import React from "react";

const PaintCanvas = (
    {
        paintMode,
        setPaintMode,
        selectedColor,
        setSelectedColor,
        handleUndo,
        undoQueue,
    }) => {

    return (
            <Stack>
                <Divider label="Tool" labelPosition="center"/>
                <Center>
                        <SegmentedControl
                            value={paintMode}
                            onChange={setPaintMode}
                            color="violet"
                            orientation="vertical"
                            data={[
                                {
                                    label: (
                                        <Center>
                                            <Tooltip label="Paint">
                                                <IconBrush style={{width: rem(16), height: rem(16)}}/>
                                            </Tooltip>
                                            {/*<Box ml={10}>Vertical</Box>*/}
                                        </Center>
                                    ),
                                    value: 'brush'
                                },
                                {
                                    label: (
                                        <Center>
                                            <Tooltip label="Line">
                                                <IconRoute2 style={{width: rem(16), height: rem(16)}}/>
                                            </Tooltip>
                                            {/*<Box ml={10}>Diagonal</Box>*/}
                                        </Center>
                                    ),
                                    value: 'line'
                                },
                                // ToDo: Move Erase Line within Line tool for UX
                                {
                                    label: (
                                        <Center>
                                            <Tooltip label="Erase Line">
                                                <IconEraser style={{width: rem(16), height: rem(16)}}/>
                                            </Tooltip>
                                            {/*<Box ml={10}>Diagonal</Box>*/}
                                        </Center>
                                    ),
                                    value: 'erase'
                                },
                                {
                                    label: (
                                        <Center>
                                            <Tooltip label="Fill">
                                                <IconBucketDroplet style={{width: rem(16), height: rem(16)}}/>
                                            </Tooltip>
                                            {/*<Box ml={10}>Diagonal</Box>*/}
                                        </Center>
                                    ),
                                    value: 'fill'
                                },
                            ]}
                        />
                    </Center>
                <Center>
                    <Tooltip label="Undo">
                            <ActionIcon
                                disabled={undoQueue.length === 0}
                                onClick={(e) => {handleUndo()
                                }}
                            >
                                <IconArrowBackUp size={20}/>
                            </ActionIcon>
                        </Tooltip>
                </Center>

                <Divider label="Color" labelPosition="center"/>
                        <ColorInput
                            value={selectedColor}
                            onChange={setSelectedColor}
                            format="hex"
                            swatches={[
                                "#25262b",
                                "#868e96",
                                "#fa5252",
                                "#e64980",
                                "#be4bdb",
                                "#7950f2",
                                "#4c6ef5",
                                "#228be6",
                                "#15aabf",
                                "#12b886",
                                "#40c057",
                                "#82c91e",
                                "#fab005",
                                "#fd7e14",
                            ]}
                        />
                </Stack>
    )

}

export default PaintCanvas;