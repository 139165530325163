import React, {useEffect, useRef} from 'react';
import {useMantineColorScheme} from "@mantine/core";

const AlphaPatternCanvas = (
    {
        gridData,
        cellSize,
        setCellSize,
        onCellClick,
        lines,
        currentLine,
        setCurrentLine,
        viewport,
        setViewport,
        gridWidth,
        gridHeight,
        currentFill,
        setCurrentFill,
        setCoordinates,
        isMouseHeld,
        setIsMouseHeld,
        paintMode,
        countCell,
        currentCells,
        accordion,
        canvasRef,
    }) => {
    const containerRef = useRef(null);
    const requestIdRef = useRef(null);
    const { colorScheme, setColorScheme } = useMantineColorScheme();


    const renderVisibleCells = () => {
        const canvas = canvasRef.current;
        const container = canvas.parentElement; // Get the container element
        const ctx = canvas.getContext('2d');

        // Adjust canvas size based on the scale
        canvas.width = (gridWidth + 2) * cellSize * viewport.scale;
        canvas.height = (gridHeight + 2) * cellSize * viewport.scale;

        // Clear canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Calculate visible cells based on viewport
        const startX = Math.floor(viewport.x / (cellSize * viewport.scale)) - 1;
        const startY = Math.floor(viewport.y / (cellSize * viewport.scale)) - 1;
        const endX = Math.min(startX + Math.ceil(container.clientWidth / (cellSize * viewport.scale)), gridWidth);
        const endY = Math.min(startY + Math.ceil(container.clientHeight / (cellSize * viewport.scale)), gridHeight);

        const xAxisXOffset = 0.25 * cellSize * viewport.scale
        const xAxisYOffset = 0.9 * cellSize * viewport.scale
        const yAxisYOffset = 0.75 * cellSize * viewport.scale
        const axisFontSize = cellSize * viewport.scale * 0.5
        const scaledCellSize = cellSize * viewport.scale

        const textColor = colorScheme === "light" ? "#000000" : "#ffffff"
        // Render visible cells
        for (let x = startX; x <= endX; x++) {
            for (let y = startY; y <= endY; y++) {
                // Calculate cell position in canvas coordinates
                const xPos = (x + 1) * scaledCellSize;
                const yPos = (y + 1) * scaledCellSize;
                ctx.fillStyle = '#242424';
                ctx.strokeStyle = '#242424';
                // Top left cell
                ctx.fillRect(startX, startY, scaledCellSize, scaledCellSize);
                // Buffer rows (empty)
                if ((x === startX || x === startX + 1) && (y === startY || y === startY + 1)) {
                    ctx.fillRect(xPos, yPos, scaledCellSize, scaledCellSize);
                    ctx.lineWidth = 0.1;
                    ctx.strokeRect(xPos, yPos, scaledCellSize, scaledCellSize);
                }
                else if (y === startY) {
                }
                else if (y === startY + 1) {
                    ctx.fillStyle = textColor;
                    ctx.font = `${axisFontSize}px Arial`;
                    ctx.fillText(`${x}`, xPos + xAxisXOffset, yPos + xAxisYOffset);
                }
                else if (x === startX) {
                }
                else if (x === startX + 1) {
                    ctx.fillStyle = textColor;
                    ctx.font = `${axisFontSize}px Arial`;
                    ctx.fillText(`${y}`, xPos, yPos + yAxisYOffset);
                } else {
                    const cell = gridData[y - 1][x - 1];

                    // Draw cell with color
                    ctx.fillStyle = cell.color;
                    ctx.fillRect(xPos, yPos, scaledCellSize, scaledCellSize);

                    // Draw black border
                    if (scaledCellSize > 10) {
                        ctx.lineWidth = 0.1
                        ctx.strokeRect(xPos, yPos, scaledCellSize, scaledCellSize);
                    }
                }
            }
        }
        // If count mode is on, make all cells grayed out except for the current row
        if (accordion === "count") {
            ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            currentCells.forEach(currentCell => {
            const xPos = (currentCell.x + 2) * scaledCellSize;
            const yPos = (currentCell.y + 2) * scaledCellSize;
            ctx.fillStyle = currentCell.color;
            ctx.fillRect(xPos, yPos, scaledCellSize, scaledCellSize);
        })
        }

        if (countCell) {
            ctx.strokeStyle = '#ffffff'
            ctx.lineWidth = 10
            ctx.strokeRect((countCell.x + 2) * scaledCellSize, (countCell.y + 2) * scaledCellSize, scaledCellSize, scaledCellSize);
        }
    };


    const handleCanvasClick = (e) => {
        const {x, y} = getMouseCoordinates(e);
        // Ensure the clicked coordinates are within the grid bounds
        if (
            x > 0 &&
            x <= gridWidth &&
            y > 0 &&
            y <= gridHeight
        ) {
            const clickedCell = gridData[y - 1][x - 1];
            onCellClick(clickedCell, x - 1, y - 1);
        }
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        canvas.addEventListener('click', handleCanvasClick);

        return () => {
            canvas.removeEventListener('click', handleCanvasClick);
        };
    }, [onCellClick, viewport]);

    const handleScroll = () => {
        // Cancel any previous animation frame requests
        cancelAnimationFrame(requestIdRef.current);

        // Use requestAnimationFrame for optimized rendering
        requestIdRef.current = requestAnimationFrame(() => {
            const container = containerRef.current;

            setViewport((prevViewport) => ({
                x: container.scrollLeft,
                y: container.scrollTop,
                scale: prevViewport.scale,
            }));

            renderVisibleCells();
        });
    };

    useEffect(() => {
        renderVisibleCells();
        renderLines();
    }, [gridData, cellSize, viewport, lines, currentLine, currentFill]);

    const renderLines = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.lineWidth = 3; // Line width
        if (currentLine.from) {
            const currentLineStartXPos = ((currentLine.from.x + 2) * cellSize) * viewport.scale + cellSize / 2 * viewport.scale;
            const currentLineStartYPos = ((currentLine.from.y + 2) * cellSize) * viewport.scale + cellSize / 2 * viewport.scale;
            const currentLineEndXPos = ((currentLine.to.x + 1) * cellSize) * viewport.scale + cellSize / 2 * viewport.scale;
            const currentLineEndYPos = ((currentLine.to.y + 1) * cellSize) * viewport.scale + cellSize / 2 * viewport.scale;
            ctx.strokeStyle = "red"; // Red color for lines
            ctx.beginPath();
            ctx.moveTo(currentLineStartXPos, currentLineStartYPos);
            ctx.lineTo(currentLineEndXPos, currentLineEndYPos);
            ctx.stroke();
        }
        lines.forEach(line => {
            const lineStartXPos = ((line.from.x + 2) * cellSize) * viewport.scale + cellSize / 2 * viewport.scale;
            const lineStartYPos = ((line.from.y + 2) * cellSize) * viewport.scale + cellSize / 2 * viewport.scale;
            const lineEndXPos = ((line.to.x + 2) * cellSize) * viewport.scale + cellSize / 2 * viewport.scale;
            const lineEndYPos = ((line.to.y + 2) * cellSize) * viewport.scale + cellSize / 2 * viewport.scale;
            ctx.strokeStyle = line.color;
            ctx.beginPath();
            ctx.moveTo(lineStartXPos, lineStartYPos);
            ctx.lineTo(lineEndXPos, lineEndYPos);
            ctx.stroke();
        })


        if (currentFill.from) {
            const scaledCellSize = cellSize * viewport.scale

            const sortedStartX = Math.min(currentFill.from.x, currentFill.to.x);
            const sortedStartY = Math.min(currentFill.from.y, currentFill.to.y);
            const sortedEndX = Math.max(currentFill.from.x, currentFill.to.x);
            const sortedEndY = Math.max(currentFill.from.y, currentFill.to.y);

            const currentFillStartXPos = (sortedStartX + 2) * scaledCellSize
            const currentFillStartYPos = (sortedStartY + 2) * scaledCellSize
            const currentFillEndXPos = ((sortedEndX - sortedStartX + 1) * (scaledCellSize))
            const currentFillEndYPos = ((sortedEndY - sortedStartY + 1) * (scaledCellSize))

            ctx.strokeStyle = 'violet';
            ctx.lineWidth = 5;

            ctx.strokeRect(currentFillStartXPos, currentFillStartYPos, currentFillEndXPos, currentFillEndYPos)
        }
    }

    const getMouseCoordinates = (e) => {
        return {
            x: Math.floor((e.clientX - canvasRef.current.offsetLeft + viewport.x - (cellSize * viewport.scale)) / (cellSize * viewport.scale)),
            y: Math.floor((e.clientY - canvasRef.current.offsetTop + viewport.y - (cellSize * viewport.scale)) / (cellSize * viewport.scale)),
        };
    }

    const handleMouseMove = (e) => {
        if (isMouseHeld && paintMode === "brush") {
            handleCanvasClick(e)
        }
        const {x, y} = getMouseCoordinates(e)
        setCurrentLine((prevLine) => ({
            ...prevLine,
            from: prevLine.from,
            to: {x, y},
        }));
        if (currentFill.from) {
            setCurrentFill((prevFill) => ({
                ...prevFill,
                from: prevFill.from,
                to: {x: x - 1, y: y - 1},
            }));
        }
        if (x > 0 && y > 0) {
            setCoordinates({x, y});
        }
    };

    const handleMouseDown = () => {
        setIsMouseHeld(true);
    }

    const handleMouseUp = () => {
        setIsMouseHeld(false);
    }

    const handleCellSize = () => {
        const canvas = canvasRef.current;
        const container = canvas.parentElement;
        const cellWidthRatio = container.clientWidth / (gridWidth + 2)
        const cellHeightRatio = container.clientHeight / (gridHeight + 2)
        setCellSize(Math.min(cellWidthRatio, cellHeightRatio))
    }


    useEffect(() => {
        // Initial width calculation
        handleCellSize()

        // Add event listener for window resize
        window.addEventListener('resize', handleCellSize);

        // Clean up the event listener
        return () => {
            window.removeEventListener('resize', handleCellSize);
        };
    }, []);


    return (
        <React.Fragment>
            <div
                ref={containerRef}
                style={{width: '100%', height: '100%', overflow: 'auto'}}
                onScroll={handleScroll}
                onMouseMove={handleMouseMove}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
            >
                <canvas
                    ref={canvasRef}
                    width={gridWidth * cellSize}
                    height={gridHeight * cellSize}
                    // style={{border: '1px solid white'}}
                />
            </div>
        </React.Fragment>
    );
};

export default AlphaPatternCanvas;
