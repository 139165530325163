import {rem, Slider} from "@mantine/core";
import {IconZoomFilled} from "@tabler/icons-react";
import React, {useState} from "react";

const ExportCanvas = (
    {
        setViewport,
    }) => {

    const [zoomDisplay, setZoomDisplay] = useState(0);


    return (
<Slider
                        value={zoomDisplay}
                        onChange={setZoomDisplay}
                        onChangeEnd={
                            (e) => {
                                setViewport((prevViewport) => (
                                    {
                                        ...prevViewport,
                                        scale: e === 0 ? 1 : (0.1667 * e) + 1
                                    }
                                ))
                            }}
                        thumbChildren={<IconZoomFilled/>}
                        color="violet"
                        defaultValue={0}
                        thumbSize={25}
                        step={5}
                        styles={{
                            thumb: {borderWidth: rem(2), padding: rem(3)},
                            markLabel: {fontSize: rem(10)}
                        }}
                        label={(value) => `${value}%`}
                        marks={[
                            {value: 0, label: '0%'},
                            {value: 25, label: '25%'},
                            {value: 50, label: '50%'},
                            {value: 75, label: '75%'},
                            {value: 100, label: '100%'},
                        ]}
                    />
    )

}

export default ExportCanvas;