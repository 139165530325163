import {Button} from "@mantine/core";
import React from "react";

const ExportCanvas = (
    {
        accordion,
        canvasRef
    }) => {

    const exportToImage = () => {
        const canvas = canvasRef.current;
        const image = new Image();
        image.src = canvas.toDataURL("image/png");
        const windowContent = `<img src="${image.src}" alt="exported-canvas-image">`;
        const printWindow = window.open();
        printWindow.document.write(windowContent);
        printWindow.document.close();
    };

    return (
        <React.Fragment>
            {accordion === "export" &&
                <Button onClick={() => {
                    exportToImage()
                }}>Export</Button>

            }
        </React.Fragment>
    )

}

export default ExportCanvas;